<template>
  <li class="ad-item" :class="{'featured':item.featured}" @click="$router.push(`/view/${item.id}/`)">
    <router-link :to="`/view/${item.id}/`">
      <figure class="ad-img">
        <img :src="item.thumbnail?item.thumbnail:'/noimage.jpg'" alt />
      </figure>
      <ad-details :item="item"></ad-details>
      <location-detail :item="item"></location-detail>
      <span class="featured-ad">Featured</span>
    </router-link>
    <!-- <ion-icon class="heart-outline" @click="addFavorite()" name="heart-outline"></ion-icon> -->
  </li>
</template>
<script>
import LocationDetail from "./LocationDetail";
import AdDetails from "./AdDetails";
export default {
  components: {
    AdDetails,
    LocationDetail
  },
  props: {
    item: {
      required: true
    }
  },
  data() {
    return {
      interval: null,
    };
  },
  methods: {
    favorite() {
      this.$cs.favorite.create
    }
  }
};
</script>
<style lang="stylus" scoped>
@media (max-width: 767px)
  .fa-inr
    position relative
    top 3px
</style>
