import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Skelton from '../views/Skelton.vue'
import Services from '../services/Services'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/signin/',
  //   name: 'Login',
  //   component: () => import('../views/Login.vue')
  // },
  // {
  //   path: '/signup/',
  //   name: 'Signup',
  //   component: () => import('../views/Signup.vue')
  // },
  {
    path: '',
    component: Skelton,
    children: [
      {
        path: '/',
        name: 'Home',
        component: Home
      },
      {
        path: '/mobile-app',
        name: 'MobileApp',
        component: () => import('../views/MobileApp.vue')
      },
      {
        path: '/about',
        name: 'About',
        component: () => import('../views/About.vue')
      },
      {
        path: '/amazon-deals/',
        name: 'AmazonDeals',
        component: () => import('../views/Deals.vue')
      },
      {
        path: '/search/',
        name: 'Search',
        component: () => import('../views/Search.vue')
      },
      {
        path: '/pricing/',
        name: 'Pricing',
        component: () => import('../views/pricing.vue')
      },
      {
        path: '/try/',
        name: 'Try',
        component: () => import('../views/Try.vue')
      },
      {
        path: '/my-account/',
        name: 'MyAccount',
        component: () => import('../views/MyAccount.vue'),
        beforeEnter: Services.auth.isAuthenticated
      },
      {
        path: '/post-free-ad/',
        name: 'PostFreeAd',
        component: () => import('../views/PostFreeAd.vue'),
        beforeEnter: Services.auth.isAuthenticated
      },
      {
        path: '/my-listing/:id/',
        name: 'PostFreeAd',
        component: () => import('../views/PostFreeAd.vue'),
        beforeEnter: Services.auth.isAuthenticated,
        props: true
      },
      {
        path: '/my-ads/',
        name: 'MyAds',
        component: () => import('../views/MyAds.vue'),
        beforeEnter: Services.auth.isAuthenticated,
        props: true
      },
      {
        path: '/my-banners/',
        name: 'MyBanners',
        component: () => import('../views/MyBanners.vue'),
        beforeEnter: Services.auth.isAuthenticated,
        props: true
      },
      {
        path: '/banner/',
        name: 'Banner',
        component: () => import('../views/Banner.vue'),
        beforeEnter: Services.auth.isAuthenticated,
        props: true
      },
      {
        path: '/make-featured/:id/',
        name: 'MakeFeatured',
        component: () => import('../views/MakeFeatured.vue'),
        beforeEnter: Services.auth.isAuthenticated,
        props: true
      },
      {
        path: '/chat/:id/',
        name: 'Chat',
        component: () => import('../views/Chat.vue'),
        beforeEnter: Services.auth.isAuthenticated,
        props: true
      },
      {
        path: '/chat/:id/:chatid/',
        name: 'Chat',
        component: () => import('../views/Chat.vue'),
        beforeEnter: Services.auth.isAuthenticated,
        props: true
      },
      {
        path: '/inbox/',
        name: 'Chat',
        component: () => import('../views/Chat.vue'),
        beforeEnter: Services.auth.isAuthenticated,
        props: true
      },
      {
        path: '/admin/users/',
        name: 'Admin',
        component: () => import('../views/admin/User.vue'),
        beforeEnter: Services.auth.isAuthenticated,
        props: true
      },
      {
        path: '/admin/products/',
        name: 'Admin',
        component: () => import('../views/admin/Products.vue'),
        beforeEnter: Services.auth.isAuthenticated,
        props: true
      },
      {
        path: '/view/:id/',
        name: 'View',
        component: () => import('../views/View.vue'),
        props: true
      },
      {
        path: '/offers/',
        name: 'Offers',
        component: () => import('../views/Offers.vue'),
      },
      {
        path: '/search/:id/',
        name: 'SearchId',
        component: () => import('../views/Search.vue'),
        props: true
      },
      {
        path: '/store/:id/',
        name: 'Store',
        component: () => import('../views/Store.vue'),
        props: true
      },
      {
        path: '/Terms+Conditions/',
        name: 'Terms+Conditions',
        component: () => import('../views/Terms&Conditions.vue')
      },
      {
        path: '/PrivacyPolicy/',
        name: 'PrivacyPolicy',
        component: () => import('../views/PrivacyPolicy.vue')
      },
      {
        path: '/contact-us/',
        name: 'ContactUs',
        component: () => import('../views/ContactUs.vue')
      },
      {
        path: '/forgot-password/',
        name: 'forgot-password',
        component: () => import('../views/ForgotPassword.vue')
      },
      {
        path: '/:id/',
        name: 'DirectSearch',
        component: () => import('../views/Search.vue'),
        props: true
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    window.scrollTo(0, 0);
  },
  routes
})

export default router
