<template>
  <div>
    <template v-if="host==='localhost'">
      Ad will be preview in only production {{adtype}}
    </template>
    <template v-else>
      <ins class="adsbygoogle"
        v-if="adtype==='hr'"
        style="display:block"
        data-ad-client="ca-pub-5710408585707855"
        :data-ad-slot="hr"
        data-ad-format="auto"
        data-full-width-responsive="true"></ins>
      <ins class="adsbygoogle"
        v-if="adtype==='standy'"
        style="display:block"
        data-ad-client="ca-pub-5710408585707855"
        :data-ad-slot="standy"
        data-ad-format="auto"
        data-full-width-responsive="true"></ins>
      <ins class="adsbygoogle"
        v-if="adtype==='header'"
        style="display:inline-block;width:728px;height:90px"
        data-ad-client="ca-pub-5710408585707855"
        data-ad-slot="2020604004"></ins>
      <ins class="adsbygoogle"
        v-if="adtype==='DHRF'"
        style="display:block;width:100%;height:90px;"
        data-ad-client="ca-pub-5710408585707855"
        data-ad-slot="5749883109"
        data-ad-format="auto"
        data-full-width-responsive="true"></ins>
      <ins class="adsbygoogle"
        v-if="adtype==='DSQ'"
        style="display:block"
        data-ad-client="ca-pub-5710408585707855"
        data-ad-slot="6709092626"
        data-ad-format="auto"
        data-full-width-responsive="true"></ins>
      <ins class="adsbygoogle"
        v-if="adtype==='DVR'"
        style="display:block"
        data-ad-client="ca-pub-5710408585707855"
        data-ad-slot="8245852803"
        data-ad-format="auto"
        data-full-width-responsive="true"></ins>
    </template>
  </div>
</template>
<script>
export default {
  props: {
    adtype:{
      default: 'hr'
    }
  },
  data() {
    return {
      host: window.location.hostname,
      clientId: 'ca-pub-5710408585707855',
      hr:'8261991996',
      standy:'1778142992',
      ads: [
        '8261991996', // hr scrapper
        '1778142992', // standy wall
      ]
    }
  },
  mounted() {
    if(this.host !== "localhost") {
      (window.adsbygoogle = window.adsbygoogle || []).push({})
    }
  }
  // props: {
//     adClient: {
//       type: String,
//       required: true
//     },
//     adSlot: {
//       type: String,
//       required: true
//     },
//     adFormat: {
//       type: String,
//       required: false,
//       default: 'auto'
//     },
//     adStyle: {
//       type: String,
//       required: false,
//       default: 'display: block'
//     }
//   },
}
</script>