<template>
  <div class="eb-authenticate">
    <div class="row margin-0">
      <div class="col-12 pad-0">
        <img src="/static/login.jpg" class="img-responsive" style="max-width:100%;" alt="">
      </div>
      <div class="col-12 eb-auth-title">
        <h3>Login / Signup to view your profile</h3>
      </div>
      <div class="col-12 eb-auth-form">
        <div class="text-center" v-if="error && error.server">
          <span class="text-danger">{{error.server}}</span>
        </div>
        <div class="row margin-0">
          <div class="col-12 pad-0">
            <sn-observer ref="authForm">
              <template v-if="stage==='NUMBER'">
                <span class="eb-form-label">Enter Mobile Number</span>
                <sn-input
                  data-input-mobile
                  type="phone"
                  :rules="['required']"
                  label="Mobile Number"
                  :show-label="false"
                  name="Mobile Number"
                  :value="payload.mobile"
                  :oerror="error.mobile"
                  v-model="payload.mobile"
                />
              </template>
              <template v-else-if="stage==='PASSWORD'">
                <span class="eb-form-label">Enter Password</span>
                <sn-input
                  data-input-password
                  type="password"
                  :show-label="false"
                  :rules="['required']"
                  label="Password"
                  name="Password"
                  placeholder="Password"
                  v-model="payload.password"
                  :oerror="error.password"
                  @enter="login()"
                  :value="payload.password"
                />
              </template>
              <template v-else-if="stage==='PASSWORD_OTP' || stage==='FORGOT_PASSWORD'">
                <div class="text-center pad-bottom-10">
                  <p>We have sent OTP to your registered mobile number <b>{{payload.mobile}}</b></p>
                </div>
                <span class="eb-form-label">Enter OTP</span>
                <sn-input
                  data-input-otp
                  style="margin-bottom: 10px;"
                  type="number"
                  :show-label="false"
                  :rules="['required']"
                  label="OTP"
                  name="OTP"
                  placeholder="Enter OTP"
                  v-model="payload.otp"
                  :oerror="error.otp"
                  @enter="login()"
                  :value="payload.otp"
                />
                <span class="eb-form-label">Enter Password</span>
                <sn-input
                  data-input-password
                  type="password"
                  :show-label="false"
                  :rules="['required']"
                  label="Password"
                  name="Password"
                  placeholder="Password"
                  v-model="payload.password"
                  :oerror="error.password"
                  @enter="login()"
                  :value="payload.password"
                />
              </template>
              <template v-else-if="stage==='USER_DETAILS'">
                <sn-input
                  data-input-account-type
                  style="margin-bottom:5px;"
                  type="radio"
                  label="Account Type"
                  name="Account Type"
                  :options="[['CUSTOMER', 'Customer'], ['BUSINESS', 'Business']]"
                  v-model="payload.user_type"
                  :value="payload.user_type"
                  :oerror="error.user_type"
                  :rules="['required']"
                  :showLabel="true"
                />
                <span class="eb-form-label">Enter Full Name</span>
                <sn-input
                  data-input-full-name
                  type="text"
                  :show-label="false"
                  :rules="['required']"
                  label="Full Name"
                  name="Full Name"
                  placeholder="Full Name"
                  v-model="payload.name"
                  :oerror="error.name"
                  @enter="login()"
                  :value="payload.name"
                />
                <span class="eb-form-label">Enter Email Address</span>
                <sn-input
                  data-input-email
                  type="email"
                  name="Email Address"
                  label="Email Address"
                  placeholder="Enter Email Address"
                  v-model="payload.email"
                  :oerror="error.email"
                  :value="payload.email"
                />
              </template>
            </sn-observer>
          </div>
          <div class="col-12 pad-0">
            <button class="btn btn-primary full-width" @click="processNext()">
              Continue
            </button>
            <div class="text-center pad-top-15" v-if="stage==='PASSWORD'">
              <span class="text-primary c-pointer" @click="openPassword()">Forgot Password?</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 eb-auth-footer">
        <span>
          By continuing, you agree to EBechana’s <a href="http://ebechana.com/terms" target="_blank" rel="noopener noreferrer">Terms &amp; Conditions</a> and <a href="http://ebechana.com/terms" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
          <span style="opacity: 0;">{{dummy}}</span>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      stage: 'NUMBER',
      dummy: 0,
      payload: {
        mobile: null,
        name: null,
        otp: null,
        password: null,
        terms: false,
        user_type: "CUSTOMER",
      },
      error: {},
    };
  },
  created() {
    this.stage = 'NUMBER';
  },
  methods: {
    processPostLogin() {
      try {
        if (that.$route.query && that.$route.query.redirect) {
          that.$router.push(that.$route.query.redirect);
        }
        that.$emit("close");
      } catch (error) {
        console.log(error);
      }
    },
    openPassword() {
      this.$cs.auth.otp({
        ...this.payload,
        action: 'FORGOT'
      }).then(
        (res) => {
          this.stage = 'FORGOT_PASSWORD';
        },
        (err) => {
          if (err.response.status === 400) {
            this.error = err.response.data;
          } else if (err.response.status === 409) {
            this.stage = 'PASSWORD';
          } else {
            this.error = {
              server: "Servers are busy, Try after sometime",
            };
          }
        }
      );
    },
    async processNext() {
      const that = this;
      that.error = {};
      const proceed = await that.$refs.authForm.validate();
      if(proceed) {
        switch(that.stage) {
          case 'NUMBER':
            this.$cs.auth.otp(this.payload).then(
              (res) => {
                this.stage = 'PASSWORD_OTP';
              },
              (err) => {
                if (err.response.status === 400) {
                  this.error = err.response.data;
                } else if (err.response.status === 409) {
                  this.stage = 'PASSWORD';
                } else {
                  this.error = {
                    server: "Servers are busy, Try after sometime",
                  };
                }
              }
            );
            break;
          case 'PASSWORD':
            const payload = {
              ...this.payload,
              mobile: this.payload.mobile.split(' ')[1]
            };
            this.$cs.auth.login(payload).then(
              (res) => {
                localStorage.setItem("Token", res.data.token);
                localStorage.setItem("user_details", JSON.stringify(res.data.user));
                that.$store.dispatch("callAccount");
                this.processPostLogin();
              },
              (err) => {
                this.error.server = "Invalid Credentials";
                this.dummy += 1;
              }
            );
            break;
          case 'PASSWORD_OTP':
            that.$cs.auth.signup(this.payload).then(
              (res) => {
                localStorage.setItem("Token", res.data.token);
                localStorage.setItem("user_details", JSON.stringify(res.data.user));
                try {
                  that.$store.dispatch("callAccount");
                  that.stage = 'USER_DETAILS';
                } catch (error) {
                  console.log(error);
                }
              },
              (err) => {
                if (err.response.status === 400) {
                  this.error = err.response.data;
                } else {
                  this.error = {
                    server: "Servers are busy, Try after sometime",
                  };
                }
              }
            );
            break;
          case 'FORGOT_PASSWORD':
            that.$cs.auth.forgotPassword(this.payload).then(
              (res) => {
                that.stage = 'NUMBER';
              },
              (err) => {
                if (err.response.status === 400) {
                  this.error = err.response.data;
                } else {
                  this.error = {
                    server: "Servers are busy, Try after sometime",
                  };
                }
              }
            );
            break;
          case 'USER_DETAILS':
            that.$cs.profile
              .update({
                data: payload,
              })
              .then(
                (res) => {
                  that.processPostLogin();
                }, (err) => {
                  if (err.response.status === 400) {
                    this.error = err.response.data;
                  } else {
                    this.error = {
                      server: "Servers are busy, Try after sometime",
                    };
                  }
              });
            break;
        }
      }
    },
    requestOTP() {
      this.error = {};
      if (this.payload.mobile === null || this.payload.mobile === "") {
        this.error.mobile = "Please enter mobile number";
        return;
      }
      if (
        this.payload.mobile &&
        this.payload.mobile.toString().length !== 10 &&
        parseInt(this.payload.mobile.toString()[0]) <= 5
      ) {
        this.error.mobile = "Enter valid mobile number";
        return;
      }
      this.error = {};
      this.$cs.auth.otp(this.payload).then(
        (res) => {
          this.stage = 2;
        },
        (err) => {
          if (err.response.status === 400) {
            this.error = err.response.data;
          } else if (err.response.status === 409) {
            this.stage = 5;
          } else {
            this.error = {
              mobile: "Servers are busy, Try after sometime",
            };
          }
        }
      );
    },
  },
};
</script>
<style lang="stylus" scoped>
.eb-authenticate {
  width: 100%;
  min-height: 40vh;
}
.eb-auth-title {
  padding: 3vh 10px;
  h3 {
    font-size: 1.1rem;
    color: #000;
    font-weight:600;
  }
}
.eb-auth-form {
  padding: 0 10px
}
.eb-auth-footer {
  padding-top: 5vh;
  text-align: center;
  a {
    color: #0052CC
    font-weight: 500;
  }
}
.eb-form-label {
  // font-weight: 500;
  margin-bottom: 4px;
}

.form-group {
  margin-bottom: 20px;
}
</style>
