<template>
  <transition name="fade">
    <div class="modal2" v-if="show">
      <div class="modal2__backdrop" @click="closeModal2()"/>
      <div class="modal2__dialog">
        <div class="modal2__header">
          <slot name="header"/>
          <button type="button" class="modal2__close" @click="closeModal2()">
			<fal-icon name="times" class="fa-1x fa" size="1x" style="font-size:0.7rem;" />
          </button>
        </div>

        <div class="modal2__body">
          <slot name="body"/>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "modal2",
  props:["show"],
  data() {
    return {
    };
  },
  methods: {
    closeModal2() {
      this.$emit("toggleModal");
    }
  }
};
</script>


<style lang="stylus" scoped>
.modal2 {
	 overflow-x: hidden;
	 overflow-y: auto;
	 position: fixed;
	 top: 0%;
	 right: 0;
	 bottom: 0%;
	 left: 0;
	 z-index: 9999;
}
 .modal2__backdrop {
	 background-color: rgba(0, 0, 0, 0.8);
	 position: fixed;
	 top: 0;
	 right: 0;
	 bottom: 0;
	 left: 0;
	 z-index: 1;
}
 .modal2__dialog {
	 background-color: #fff;
	 position: relative;
	 width: 410px;
	 margin: 10vh auto 0;
	 min-height: 60vh;
	 display: flex;
	 flex-direction: column;
	 border-radius: 2px;
	 z-index: 2;
}
 @media screen and (max-width: 992px) {
	 .modal2__dialog {
		 width: 90%;
	}
}
 .modal2__close {
	 width: 30px;
	 height: 30px;
   background-color: transparent;
   border: none;
   outline: none;
   ion-icon {
	   font-size: 2em;
   }
}
 .modal2__header {
	 padding: 20px 20px 10px;
	 display: flex;
	 align-items: flex-start;
	 justify-content: space-between;
}
 .modal2__body {
	 padding: 10px 20px 10px;
	 overflow: auto;
	 display: flex;
	 flex-direction: column;
	 align-items: center;
   justify-content: center;
   
}
 .modal2__footer {
	 padding: 10px 20px 20px;
}
 .fade-enter-active, .fade-leave-active {
	 transition: opacity 0.2s;
}
 .fade-enter, .fade-leave-to {
	 opacity: 0;
}
 
</style>