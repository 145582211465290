import Vue from 'vue'
import Vuex from 'vuex'
import account from "./account";

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    account: account,
  },
  state: {
    connected: false,
    requestLogin: false,
    cats: null,
    subCats: null,
    catMappings: {},
    banners: [
      // {
      //   image: "/ads/pmic.webp",
      //   link: "https://chat.whatsapp.com/GY7inxzH5Hz1MyumIHhkPJ"
      // },
      // {
      //   image: "/ads/kisan365.webp",
      //   link: "https://kisan365.com"
      // },
      {
        image: "/ads/aacharya.webp",
        link: "https://aacharya.net"
      },
      // {
      //   image: "/ads/pmic1.webp",
      //   link: "https://instagram.com/pan_india_models_club?igshid=18yi3o5g4vai3"
      // },
      {
        image: "/ads/advertise.webp",
        link: "tel:+918985948415"
      },
      {
        image: "/ads/waasher.webp",
        link: "https://waasher.com"
      }
    ],
    vipbanners: [
      // {
      //   image: "/ads/pmic.webp",
      //   link: "https://chat.whatsapp.com/GY7inxzH5Hz1MyumIHhkPJ"
      // },
      {
        image: "/amazon/Prime_Bounty_Creatives/XCM_Manual_1209672__640x290_.jpg",
        link: "https://www.amazon.in/tryprime?tag=ebechana-21"
      },
      {
        image: "/ads/kisan365.webp",
        link: "https://kisan365.com"
      },
      {
        image: "/ads/aacharya.webp",
        link: "https://aacharya.net"
      },
      {
        image: "/ads/pmic1.webp",
        link: "https://instagram.com/pan_india_models_club?igshid=18yi3o5g4vai3"
      },
      {
        image: "/ads/advertise.webp",
        link: "tel:+918985948415"
      },
      {
        image: "/ads/waasher.webp",
        link: "https://waasher.com"
      }
    ],
    ads: [],
    location: JSON.parse(localStorage.getItem('my-location')),
    bannerPool: null
  },
  mutations: {
    SOCKET_CONNECT(state) {
      state.connected = true;
    },
    socket_disconnect(state, data) {
      state.connected = false;
    },
    addBanner(state, data) {
      state.bannerPool = data;
      if(data.length>0) {
        const ban = state.banners;
        state.banners = [...data[0].assets, ...ban];
      }
    },
    addCats(state, data) {
      state.cats = data;
      let lookupObj = {};
      let lookupMapping = {};
      data.forEach((item) => {
        lookupMapping[item.id] = item.title;
        lookupObj[item.id] = item.cat_list;
      });
      state.subCats = lookupObj;
      state.catMappings = lookupMapping;
    },
    updateLocation(state, location) {
      state.location = location;
      localStorage.setItem('my-location', JSON.stringify(location));
    },
    requestLogin(state, value) {
      state.requestLogin = value;
    }
  },
  actions: {
    requestLogin(context, value=true) {
      context.commit("requestLogin", value);
    },
    socket_connect(context) {
      context.commit("SOCKET_CONNECT");
      // this._vm.$socket.emit("register", { email: "venkatasaisoft@gmail.com" });
    },
    callStatic(context) {
      context.dispatch("updateLocation");
      context.dispatch("getCats");
      const profile = JSON.parse(localStorage.getItem("user_details")) || null;
      if(profile) {
        context.dispatch("callAccount");
      }
    },
    updateLocation(context) {
      // if(context.state.location === null) {
      //   this._vm.$cs.geo.detectLocation().then(location => {
      //     location.name = location.city;
      //     context.commit('updateLocation', location);
      //   });
      // }
    },
    updateCustomLocation(context, data) {
      if(data) {
        context.commit('updateLocation', {
          ...data,
          city: data.name
        });
      } else {
        context.commit('updateLocation', null);
      }
    },
    getBanners(context) {
      this._vm.$cs.bannerpool.list({
        pool_type: "banners",
        sort: "-created_on",
        pageSize: 2
      }).then(
        (res) => {
          context.commit("addBanner", res.data.results);
        },
        (err) => {
        }
      );
    },
    getCats(context) {
      this._vm.$cs.lookup.list({
        resource: `${this._vm.$cs.lookup.api}cats/`
      }).then(
        (res) => {
          context.commit("addCats", res.data);
        },
        (err) => {
        }
      );
    },
  },
  getters: {},
})
