<template>
    <div class="ad-detail" :class="tag">
      <span class="price">
        <fal-icon name="rupee-sign" height="2rem" />
        <!-- <span class="fa fa-inr"></span> -->
        {{parseFloat(item.price).toLocaleString()}}
        <template v-if="item.sub_category==='Jobs'">
          <template v-if="item.data && item.data.income_to && item.data.income_to!==0">- {{parseFloat(item.data.income_to).toLocaleString()}}</template>
          <template v-if="item.data && item.data.income_type"> | {{item.data.income_type}}</template>
        </template>
      </span>

      <template v-if="item.category==='Mobiles & Tablets'">
        <span v-if="item.data && item.data.brand && item.data.brand!=='Other'" class="tag">{{item.data.brand}}</span>
      </template>
      <template v-if="item.category==='Cars & Bikes'">
        <span class="tag">
          <span v-if="item.data && item.data.brand && item.data.brand!=='Other'" class="tag">{{item.data.brand}}, </span>
          <span v-if="item.data && item.data.year" class="tag">{{item.data.year}}, </span>
          <span v-if="item.data && item.data.mileage" class="tag"> {{item.data.mileage}}KM</span>
        </span>
      </template>
      <template v-if="item.sub_category==='Houses & Apartments'">
        <span class="tag">
          <span v-if="item.data && item.data.bedrooms && item.data.bedrooms>0" class="tag">{{item.data.bedrooms}}BHK</span>
        </span>
      </template>
      <span class="title" style="text-overflow: ellipsis;white-space: nowrap;overflow: hidden;">
        {{item.title}}
      </span>
    </div>
</template>
<script>
export default {
    props: ['item', 'tag']
}
</script>
<style lang="stylus" scoped>
.ad-detail
    // height 85px
    display flex
    flex-direction column
    flex 1
    position relative
    padding 6px 14px 8px
    .price
        color #002f34
        display block
        font-weight 700
        font-size 20px
        white-space nowrap
        overflow hidden
        text-overflow ellipsis
        margin 0
        text-shadow: 0 0 5px rgba(0,0,0,0.05);
        letter-spacing: normal
    .tag
        color #002f34
        font-size 13px
        margin 2px 0 0
        text-overflow ellipsis
        overflow hidden
        white-space nowrap
        text-transform uppercase
    .title
        font-weight 400
        white-space nowrap
        overflow hidden
        text-overflow ellipsis
        margin 0px
        color #002f3499
        font-size 14px
        letter-spacing: normal
    &.view-page
        height auto
        padding 10px 12px
        .price
            font-weight: 700;
            margin-bottom: 8px;
            color: #002f34;
            font-size: 28px;
            line-height: 30px;
        .tag
            margin: 0;
            font-size: 16px;
            color: #002f34;
            font-weight: 400;
            font-style: normal;
            font-stretch: normal;
            line-height 20px
        .title
            font-size: 16px;
</style>