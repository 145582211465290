<template>
  <div class="home">
    <div class="row margin-0 justify-content-center">
      <div class="col-12 pad-0">
        <div class="row margin-0">
          <div class="col-12 pad-0">
            <!-- <eb-slider /> -->
            <!-- <Categories /> -->
            <img src="/home.png" alt="" srcset="" class="img-fluid" />
          </div>
        </div>
        <div class="pad-5">
          <my-ad :adtype="(windowRef && windowRef.screen.width > 400)?'DHRF':'header'" />
        </div>

        <div class="pad-top-10">
          <div class="row margin-0">
            <div class="col-12 pad-0">
              <h2 class="h2-heading">Explore Popular Categories <small class="view-all f-right c-pointer">See all</small></h2>
              <ul class="cat-list-cricles">
                <li>
                  <div class="cat-tile">
                    <img src="/local/mobile.png" alt="" srcset="">
                  </div>
                  <span class="cat-link">Mobiles</span>
                </li>
                <li>
                  <div class="cat-tile">
                    <img src="/local/bike.png" alt="" srcset="">
                  </div>
                  <span class="cat-link">Bikes</span>
                </li>
                <li>
                  <div class="cat-tile">
                    <img src="/local/car.png" alt="" srcset="">
                  </div>
                  <span class="cat-link">Cars</span>
                </li>
                <li>
                  <div class="cat-tile">
                    <img src="/local/electronics.png" alt="" srcset="">
                  </div>
                  <span class="cat-link">Electronics</span>
                </li>
                <li>
                  <div class="cat-tile">
                    <img src="/local/property.png" alt="" srcset="">
                  </div>
                  <span class="cat-link">Properties</span>
                </li>
                <li>
                  <div class="cat-tile">
                    <img src="/local/jobs.png" alt="" srcset="">
                  </div>
                  <span class="cat-link">Jobs</span>
                </li>
                <li>
                  <div class="cat-tile">
                    <img src="/local/car.png" alt="" srcset="">
                  </div>
                  <span class="cat-link">Cars</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div
          class="row margin-0 justify-content-center recent-postings bg-dk"
          v-if="false"
        >
          <div class="col-md-11 col-lg-12 col-12 pad-5">
            <h4 class="margin-bottom-10 pad-left-5 d-block">
              Featured Listings
            </h4>
            <div>
              <!-- <ul id="featuredContent" class="ads-list" style="overflow-x:auto;display:flex;padding-bottom:10px;"> -->
              <ul id="featuredContent" class="ads-list">
                <template v-if="featuredProducts && featuredProducts.results">
                  <template v-for="(item, index) in featuredProducts.results">
                    <ad v-if="index < 8" :item="item" :key="index"></ad>
                  </template>
                  <div class="arrow-right" @click="scrollRight()">
                    <ion-icon name="chevron-forward-outline"></ion-icon>
                  </div>
                </template>
                <template v-else>
                  <template v-for="index in 8">
                    <ad-loading :key="index"></ad-loading>
                  </template>
                </template>
              </ul>
            </div>
          </div>
        </div>

        <AdList view="HOME" />

        <div class="row margin-0 justify-content-center bg-white">
          <div class="col-md-12 col-lg-10 col-xl-10 col-12 empd">
            <h4 class="text-center">How it works for you?</h4>
            <div class="row justify-content-between">
              <div class="col-md-3 pad-0 text-center">
                <img src="/img/create.webp" style="max-width: 50%" alt="" />
                <h5>Create an Account</h5>
                <p>
                  Create an account based on your requirement with your mobile
                  number.
                </p>
              </div>
              <div class="col-md-3 pad-0 text-center">
                <img src="/img/post.webp" style="max-width: 50%" alt="" />
                <h5>Post Free Ad</h5>
                <p>
                  Post a new ad with an attractive title and photo to catch the
                  people's attention.
                </p>
              </div>
              <div class="col-md-3 pad-0 text-center">
                <img src="/img/feature.webp" style="max-width: 75%" alt="" />
                <h5>Make as featured</h5>
                <p>
                  Featured ad will be our first priority in the listing. Get
                  cashback, If your product sold out with in time.
                </p>
              </div>
              <div class="col-12 text-center pad-top-20">
                <button
                  class="btn btn-primary"
                  @click="$store.dispatch('requestLogin')"
                >
                  Create a free Account
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row margin-10 justify-content-center" v-if="false">
          <div class="col-12 pad-10 text-center">
            <h4>Great Deals for you</h4>
          </div>
          <!-- <div class="col-md-6 col-12 pad-bottom-25">
            <carousel :autoplay="true" :autoplayTimeout="6000" :paginationPadding="5" :paginationSize="7" :perPage="1" :paginationEnabled="false">
              <template v-if="banners">
                <slide v-for="(item, idx) in banners" :key="idx">
                  <div class="slider-content">
                    <img class="img-fluid advert-image" @click="openLink(item.link)" :src="item.image" alt="">
                  </div>
                </slide>
              </template>
            </carousel>
          </div>
          <div class="col-md-6 col-12 pad-bottom-25">
            <carousel :autoplay="true" :autoplayTimeout="10000" :paginationPadding="0" :paginationSize="7" :perPage="1" :paginationEnabled="false">
              <template v-if="banners">
                <slide v-for="(item, idx) in banners" :key="idx">
                  <div class="slider-content">
                    <img class="img-fluid advert-image" @click="openLink(item.link)" :src="item.image" alt="">
                  </div>
                </slide>
              </template>
            </carousel>
          </div> -->
        </div>
        <div class="row margin-0 app-block justify-content-center">
          <div class="col-12 block1">
            <h2>Download the app and enjoy unlimited browsing.</h2>
            <p>
              Feel the experience in your hand. Be a part of accessing all new
              offers
            </p>
            <a
              href="https://play.google.com/store/apps/details?id=com.codejunkers.ebechana"
              target="_blank"
            >
              <img
                src="/play-store.png"
                style="max-width: 20%"
                class="img-fluid"
                alt=""
                srcset=""
              />
            </a>
            <a
              href="https://apps.apple.com/us/app/ebechana/id1529085800"
              target="_blank"
              class="margin-left-10"
            >
              <img
                src="/app-store.png"
                style="max-width: 20%"
                class="img-fluid"
                alt=""
                srcset=""
              />
            </a>
            <!-- <br> -->
            <!-- <iframe style="width:120px;height:240px;" marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-in.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=IN&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=ebechana-21&language=en_IN&marketplace=amazon&region=IN&placement=B087S1KYH4&asins=B087S1KYH4&linkId=713adb5ff2741d64c2ac0e2252986ad5&show_border=true&link_opens_in_new_window=true"></iframe> -->
          </div>
        </div>
        <div class="pad-15" style="background-color: #fff">
          <h5
            style="font-size: 1.2em; letter-spacing: 0.3px; line-height: 1.4em"
            class="text-center margin-0"
          >
            <b>People searching</b>
          </h5>
          <ul class="keywords-list">
            <li><fal-icon name="search" /> sell on ebechana</li>
            <li><fal-icon name="search" /> buy on ebechana</li>
            <li><fal-icon name="search" /> used cars on ebechana</li>
            <li><fal-icon name="search" /> used mobiles on ebechana</li>
            <li><fal-icon name="search" /> laptops on ebechana</li>
            <li>
              <fal-icon name="search" /> sell in hyderabad on ebechana
            </li>
            <li><fal-icon name="search" /> fashion on ebechana</li>
            <li><fal-icon name="search" /> new mobiles on ebechana</li>
            <li><fal-icon name="search" /> old mobiles on ebechana</li>
          </ul>
        </div>
        <div class="pad-15" style="background-color: #c6e5f6">
          <h5
            style="font-size: 1.2em; letter-spacing: 0.3px; line-height: 1.4em"
            class="text-center margin-0"
          >
            Need help? write email to our support team 24/7 at info@ebechana.com
          </h5>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import { Carousel, Slide } from "vue-carousel";
import TimeLabel from "@/components/TimeLabel.vue";
import Categories from '@/views/components/Categories.vue';
import AdList from '@/components/AdList.vue';
import { mapState } from "vuex";
export default {
  components: {
    Carousel,
    Slide,
    TimeLabel,
    VueSlickCarousel,
    Categories,
    AdList
  },
  computed: {
    ...mapState({
      banners: (state) => state.banners,
    }),
    windowRef: function() {
      if(window) {
        return window;
      }
      return null;
    }
  },
  name: "Home",
  data() {
    return {
      settings: {
        centerMode: true,
        centerPadding: "20%",
        focusOnSelect: true,
        infinite: true,
        slidesToShow: 1,
      },
      visible_corona: true,
      params: {
        location: null,
        category: null,
      },
      categories: null,
      cities: null,
      products: null,
      search_text: null,
      featuredProducts: null,
      loading: true,
    };
  },
  created() {
    this.getProducts();
    this.getFeaturedProducts();
  },
  methods: {
    openLink(href) {
      Object.assign(document.createElement("a"), {
        target: "_blank",
        href,
      }).click();
    },
    scrollRight() {
      document
        .getElementById("featuredContent")
        .scrollBy(window.innerWidth * 0.85, 0);
    },
    startSearch() {
      if (this.search_text === null) {
        this.$router.push("/search");
      } else {
        let root = "/search/" + this.search_text;
        this.$router.push(root);
      }
    },
    getProducts() {
      this.$cs.product
        .list({
          params: {
            sort: "-created_on",
          },
          pageSize: 12,
        })
        .then((res) => {
          this.products = res.data;
          this.loading = false;
        });
    },
    getFeaturedProducts() {
      this.$cs.product
        .list({
          params: {
            featured: true,
            sort: "-created_on",
          },
          pageSize: 10,
        })
        .then((res) => {
          this.featuredProducts = res.data;
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="stylus" scoped>
.cat-list-cricles {
  list-style: none;
  padding: 0;
  margin: 0 0 10px;
  li {
    float: left;
    text-align: center;
    padding-right: 5px;
    max-width: calc(100%/7);
    cursor: pointer;
    img {
      max-width: 100%;

    }
    .cat-link {
      font-weight: 600;
      font-size: 14px;
    }
  }
}
.arrow-right {
  position: absolute;
  right: 0;
  top: 40%;
  height: 20%;
  background: #fff;
  box-shadow: -1px 0px 10px #ddd;
  cursor: pointer;
  padding: 5px;
  border: 1px solid #ddd;
  display: flex;
  justify-content: center;
  align-items: center;

  ion-icon {
    font-size: 2em;
  }
}

.app-block {
  background-color: #E7EBEE;
  padding: 20px;

  .block1 {
    padding: 10px;
    // text-align center
  }
}

.category-list-head {
  position: absolute;
  top: 100%;

  .category-list {
    list-style: none;
    margin: 0px;
    padding: 0px;
  }
}

.home {
  min-height: 100vh;
  background-color: #fff;

  .home-banner {
    background-color: #0594d9;

    .search-form {
      min-height: 50vh;
      color: white;
      position: relative;
      z-index: 1;

      & .search-form-bg {
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        opacity: 0.7;
        background-image: url('/home-ban-bg.png');
        background-size: 20vw;
        background-repeat: round;
        z-index: -1;
      }

      .form-block {
        padding: 15vh 0px 0px;

        p {
          font-size: 1.5em;
        }

        .form-list {
          max-width: 70%;
          margin: 0 auto;

          .pad-0 {
            background-color: #ffffff;

            select {
              border: 0px;
              height: 50px;
            }
          }

          .form-control {
            border-radius: 0px;
            outline: none;
            border: none;
            padding: 25px;

            &.b-right {
              border-right: 1px solid #ddd;
            }
          }

          .btn {
            border-radius: 0px;
            outline: none;
            border: none;
            padding: 14px;
          }
        }
      }
    }
  }
}

.categories {
  padding: 40px 30px 20px;

  h2 {
    text-align: center;
  }

  .cat-list {
    list-style: none;
    margin: 0px;
    padding: 0px;
    width: 100%;

    li {
      margin: 1%;
      background-color: white;
      width: 23%;
      float: left;
      text-align: center;
      padding: 3%;
      border: 1px solid #eee;
      font-weight: bold;
      letter-spacing: 0.5px;
      cursor: pointer;

      ion-icon {
        font-size: 3em;
      }

      .title {
        display: block;
      }
    }
  }
}

.recent-postings {
  padding: 17px 0px;

  &.bg-dk {
    background-color: #dddddd;
  }
}

.ads-list {
  li {
    width: 24%;
    min-width: 24%;
  }
}

.empd {
  padding: 25px;

  h5 {
    font-weight: bold;
  }

  p {
    color: #666;
    text-align: justify;
  }
}

.keywords-list {
  list-style: none;
  margin: 10px auto;
  padding: 0;
  max-width: 80%;
  display: flow-root;

  li {
    float: left;
    padding: 5px 10px;
    border: 1px solid #ddd;
    border-radius: 15px;
    margin: 5px;
    font-size: 0.8em;
    cursor: pointer;
  }
}

.top-slider {
  padding-top: 50px;
  background: #fff;
  display: block;

  .slider-content {
    padding: 5px;

    .advert-image {
      border-radius: 4px;
      filter: brightness(0.95);
    }
  }
}

.customDots {
  bottom: 10%;
}

@media (max-width: 767px) {
  .top-slider {
    padding-top: 10px;
  }

  .mb-bg-white {
    background-color: #ffffff;
  }

  .search-form {
    min-height: 40vh !important;

    .form-block {
      padding: 10vh 0px 0px !important;

      p {
        font-size: 0.8em !important;
      }

      h1 {
        font-size: 1em;
      }

      .form-list {
        .form-control {
          padding: 15px !important;
        }

        .btn {
          padding: 7px !important;
          width: auto !important;
          margin: 20px 0px 0px !important;
        }
      }
    }
  }

  .categories {
    padding: 5px 10px 10px;

    h2 {
      margin: 7px 7px 10px;
      font-size: 1em;
      text-align: left;
      color: #666666;
    }

    .cat-list {
      display: flex;
      overflow-x: auto;

      li {
        display: inline-block;
        // width 31.3%!important
        min-width: 20% !important;
        max-width: 50% !important;
        overflow: hidden;
        // height 13vh
        padding: 3.5% 2.5% !important;
        font-size: 0.7em !important;
        border: none;

        ion-icon {
          font-size: 2em !important;
        }

        .count {
          display: none;
        }
      }
    }
  }

  .recent-postings {
    padding: 14px 5px;

    h3 {
      padding: 3px 10px;
      font-size: 1em;
    }
  }

  .app-block {
    h2 {
      font-size: 1.3em;
    }

    .img-fluid {
      max-width: 45%;
    }
  }

  .keywords-list {
    max-width: 100%;

    li {
      font-size: 0.55em;
    }
  }
}
</style>
