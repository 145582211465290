import Vue from 'vue'
import axios from "axios";
import Snatch from '@venkatasaikatepalli/snatch';

import App from './App.vue'
import router from './router'
import store from './store'
import Services from "@/services/Services.vue";
import Constants from './constants'

import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import "../node_modules/@venkatasaikatepalli/snatch/snatch.css";
import './assets/css/new/EBechana.styl'

import TimeLabel from '@/components/TimeLabel.vue'
import ChatTimeLabel from '@/components/ChatTimeLabel.vue'
import Modal from '@/components/Modal.vue'
import Ad from '@/components/Ad.vue'
import AdLoading from '@/components/AdLoading.vue'
import BannerAd from '@/components/BannerAd.vue'
// import VueSocketIO from "vue-socket.io";
import userMixin from './mixins/userMixin';


// Import Mappings

function sendStats(status, data) {
  console.log(data);
}

axios.interceptors.request.use(
  (config) => {
    if (
      (config.headers["Content-Type"] &&
        config.headers["Content-Type"].startsWith("application/json")) ||
      !config.headers["Content-Type"]
    ) {
      if (localStorage.getItem("Token") && !config.headers.Authorization) {
        config.headers.Authorization = `Bearer ${localStorage.getItem(
          "Token"
        ) || null}`;
      }
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(null, (error) => {
  if (error.config && error.response) {
    switch (error.response.status) {
      case 400:
        if (typeof error.response.data === 'object') {
          Object.keys(error.response.data).forEach(
            (field) =>
            (error.response.data[field] =
              error.response.data[field][0] || error.response.data[field])
          );
        }
        error.response.data = error.response.data;
        break;
      case 401:
        localStorage.clear();
        window.location.href = "/";
        break;
      case 403:
        break;
      case 404:
      case 405:
      case 500:
        sendStats(error.response.status, error.response.data);
        break;
    }
  }
  return Promise.reject(error);
});

Vue.component('time-label', TimeLabel);
Vue.component('chat-time-label', ChatTimeLabel);
Vue.component('Modal', Modal);
Vue.component('ad', Ad);
Vue.component('ad-loading', AdLoading);
Vue.component('banner-ad', BannerAd);

Vue.use(Snatch, 'en');
Vue.use(axios);

Vue.filter('reverse', function(value) {
  return value.slice().reverse();
});

/* Bind firebase to your Vue instance */
Vue.config.productionTip = false
Vue.config.ignoredElements = ['ion-icon']
Vue.prototype.$cs = Services;
Vue.prototype.$constants = Constants;
Vue.prototype.$width = screen.width;
Vue.prototype.$devices = {
  tab: 768,
  maxTab: 1025,
  desktop: 1224
};
Vue.mixin(userMixin);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

import MyAd from '@/components/MyAd'
// import './assets/css/style.styl'
// Icons & Packs
// import { library, config } from "@fortawesome/fontawesome-svg-core";
// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
// import { fas } from '@fortawesome/pro-solid-svg-icons'
// import { far } from '@fortawesome/pro-regular-svg-icons'
// import { fal } from '@fortawesome/pro-light-svg-icons'
// import { fad } from '@fortawesome/pro-duotone-svg-icons'
// import { fat } from '@fortawesome/pro-thin-svg-icons'
// import { fass } from '@fortawesome/sharp-solid-svg-icons'
// import FalIcon from './plugins/snatch/SnatchFntIcon.vue'
// config.autoAddCss = false;
// library.add(fas);
// library.add(far);
// library.add(fal);
// library.add(fad);
// library.add(fat);
// library.add(fass);
// Start Importing
// snatch
// import SnatchInput from '@/plugins/snatch/SnatchInput.vue';
// import SnatchObserver from '@/plugins/snatch/SnatchObserver';
// Vue.component('sn-input', SnatchInput);
// Vue.component('sn-observer', SnatchObserver);
      // eslint-disable-next-line
Vue.component('my-ad', MyAd);
// Vue.component("fal-icon", FalIcon);
// Vue.component("font-awesome-icon", FontAwesomeIcon);
// import VueAnalytics from 'vue-analytics'
// Vue.use(VueAnalytics, {
//   id: 'UA-159250119-1',
//   checkDuplicatedScript: true,
//   router,
//   disabled: () => {
//     return process.env.VUE_SAI!=="prod"
//   }
// })
  // slice to make a copy of array, then reverse the copy
  // Vue.use(
  //   new VueSocketIO({
  //     debug: false,
  //     connection: process.env.VUE_APP_SOCKET_URL,
  //     vuex: {
  //       store,
  //       actionPrefix: "socket_",
  //       mutationPrefix: "socket_",
  //     },
  //     options: { transports: ['websocket'] } //Optional options
  //   })
  // );
// setTimeout(function () {
//   // document.dispatchEvent(new Event('custom-post-render-event'));
// }, 3000);
  // mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
