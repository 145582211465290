<template>
  <span class="time-label">
    <span v-if="days">{{date.getDate()}} {{date.toLocaleString('default', { month: 'short' })}}</span>
    <span v-else-if="hours">{{new Date(date).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}}</span>
    <span v-else-if="minutes">{{new Date(date).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}}</span>
    <span v-else-if="seconds">{{new Date(date).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}}</span>
    <span v-else>{{new Date(date).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}}</span>
  </span>
</template>
<script>
export default {
    props:{
    date:{
      required:true
    },
    label: {
      default: true
    }
  },
  data() {
    return {
      interval:null,
      days:0,
      hours:0,
      minutes:0,
      seconds:0,
      intervals:{
        second: 1000,
        minute: 1000 * 60,
        hour: 1000 * 60 * 60,
        day: 1000 * 60 * 60 * 24
      }
    }
  },
  mounted() {
    this.interval = setInterval(() => {
      this.updateDiffs();
    },1000);
    
    this.updateDiffs();
  },
  destroyed() {
    clearInterval(this.interval); 
  },
  methods:{
    updateDiffs() {
      //lets figure out our diffs
      let diff = Math.abs(Date.now() - this.date.getTime());
      this.days = Math.floor(diff / this.intervals.day);
      diff -= this.days * this.intervals.day;
      this.hours = Math.floor(diff / this.intervals.hour);
      diff -= this.hours * this.intervals.hour;
      this.minutes = Math.floor(diff / this.intervals.minute);
      diff -= this.minutes * this.intervals.minute;
      this.seconds = Math.floor(diff / this.intervals.second);
    }
  }
}
</script>
<style lang="stylus" scoped>
.time-label
  text-transform uppercase
  font-weight 500
  span
    font-size 0.8em
</style>
