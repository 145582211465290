<template>
  <div>
    <h3>Browse Categories</h3>
    <div class="row margin-0">
      <div class="col-12 pad-0">
        <ul class="list-inline">
          <template v-for="(item, idx) in categoriesList">
            <li :key="idx">
              <img :src="item.imgSrc" alt="" srcset="">
              <label for="">{{item.label}}</label>
            </li>
          </template>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      categoriesList: [
        {
          id: "Mobiles+Tablets",
          label: "Mobiles",
          searchValue: {
            cat: "Mobiles+Tablets",
          },
          color: "#4062BB",
          iconID: "mobile",
          imgSrc: "/icons/phone.png"
        },
        {
          id: "Cars+Bikes",
          label: "Bikes",
          searchValue: {
            cat: "Cars+Bikes",
          },
          color: "#4062BB",
          iconID: "bike",
          imgSrc: "/icons/bike.png"
        },
        {
          id: "Cars+Bikes",
          label: "Cars",
          searchValue: {
            cat: "Cars+Bikes",
          },
          color: "#4062BB",
          iconID: "car",
          imgSrc: "/icons/car.png"
        },
        {
          id: "Electronics+Appliances",
          label: "Electronics",
          searchValue: {
            cat: "Electronics+Appliances",
          },
          color: "#4062BB",
          iconID: "electronics",
          imgSrc: "/icons/electronic.png"
        },
        {
          id: "Properties",
          label: "Properties",
          searchValue: {
            cat: "Properties",
          },
          color: "#4062BB",
          iconID: "properties",
          imgSrc: "/icons/property.png"
        },
        {
          id: "Jobs",
          label: "Jobs",
          searchValue: {
            cat: "Jobs",
          },
          color: "#4062BB",
          iconID: "jobs",
          imgSrc: "/icons/jobs.png"
        },
        {
          id: "Fashion+Jwellery",
          label: "Fashion",
          searchValue: {
            cat: "Fashion+Jwellery",
          },
          color: "#4062BB",
          iconID: "fashion",
          imgSrc: "/icons/fashion.png"
        },
        {
          id: "all",
          label: "See All",
          searchValue: {
            cat: "all",
          },
          color: "#4062BB",
          iconID: "all",
          imgSrc: "/icons/all.png"
        },
      ],
    };
  },
};
</script>