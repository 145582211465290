<template>
  <div class="banner-ad">
    <a :href="banner.link" target="_blank" rel="noopener noreferrer">
      <img class="img-fluid" :src="banner.image" alt="">
    </a>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      banner: null,
      bindex: 0,
      timer: null
    }
  },
  computed: {
    ...mapState({
      vipbanners: (state) => state.vipbanners,
    }),
  },
  created() {
    if(this.vipbanners.length>0) {
      this.banner = this.vipbanners[0];
    }
    // this.rotateBanner();
  },
  destroyed() {
    this.timer = null;
  },
  methods: {
    rotateBanner() {
      this.timer = setInterval(() => {
        if(this.bindex+1===this.vipbanners.length) {
          this.bindex = 0;
          this.banner = this.vipbanners[this.bindex];
        } else {
          this.bindex +=1;
          this.banner = this.vipbanners[this.bindex];
        }
      }, 20000);
    }
  }
}
</script>
<style scoped lang="stylus">

</style>
