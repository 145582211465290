<template>
    <div class="location-text">
        <span class="location">
            <span class="fa fa-map-marker"></span>
            <span v-if="item.area" class="hidden-xs"> {{item.area.slice(0, 18)}}, </span>
            <span> {{item.city}} </span>
        </span>
        <span class="time">
            <time-label style="float:right;" :date="new Date(item.created_on)"></time-label>
        </span>
    </div>
</template>
<script>
export default {
    props:['item']
}
</script>
<style lang="stylus" scoped>
.location-text
    padding 8px 12px
    display flex
    justify-content space-between
    text-transform uppercase
    color #002f3399
    text-transform uppercase
    *
        font-size 10px
        font-weight 400
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    .time
        width 60px
</style>