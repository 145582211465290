<template>
  <div class="row margin-0 justify-content-center recent-postings">
    <div class="col-12 pad-0">
      <template v-if="view === 'HOME'">
        <h5 class="margin-bottom-10 pad-left-10 d-block">
          {{ label }}
          <span
            class="txt-theme f-right c-pointer"
            @click="$router.push(`/search/`)"
            >VIEW ALL</span
          >
        </h5>
      </template>
      <template v-else-if="view === 'SEARCH'">
        <div class="row margin-0">
          <div class="col pad-left-5">
            <span v-if="products && products.count>=0">{{products.count}} results found</span>
          </div>
          <div class="col pad-right-5 text-right">
            <div class="filter-list hidden-xs">
              <h6>
                Filter
                <select name="" class="form-control-sm" v-model="order" placeholder="Sort by" id="" style="border-radius:none;max-width:200px;">
                  <option value="-created_on">Most Recent</option>
                  <option value="price">Price Low to High</option>
                  <option value="-price">Price High to Low</option>
              </select>
              </h6>
            </div>
          </div>
        </div>
      </template>
      <div>
        <ul class="ads-list">
          <template v-if="productsList">
            <template v-if="productsList.length===0">
              <div class="text-center pad-20">
                <div class="pad-top-20">
                  <h5>Oops... We can't find anything that matches your search and filter.</h5>
                  <span>Try search for something more general, change the filters or check for spelling mistakes</span>
                </div>
                <br>
                <button class="btn btn-link margin-right-10" @click="$emit('reset')">Browse All Categories</button>
              </div>
            </template>
            <template v-else v-for="(item, index) in productsList">
              <ad :item="item" :key="index"></ad>
            </template>
          </template>
          <template v-else>
            <template v-for="index in 8">
              <ad-loading :key="index"></ad-loading>
            </template>
          </template>
        </ul>
        <div class="text-center pad-15" v-if="products && products.next">
          <button class="btn btn-link" @click="loadMore(products.next)">Load More</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    label: {
      require: false,
      default: "Fresh Postings"
    },
    view: {
      require: false,
      default: null
    },
    filter: {
      require: false,
      default: null
    }
  },
  data() {
    return {
      currentLocation: localStorage.getItem('location')?JSON.parse(localStorage.getItem('location')):null,
      order: '-created_on',
      products: null,
      productsList: null,
      loading: true,
    };
  },
  created() {
    this.getProducts();
  },
  watch: {
    'filter.category': function(oldVal, newVal) {
      this.getProducts();
    },
    'filter.sub_category': function(oldVal, newVal) {
      this.getProducts();
    },
    order: function(oldVal, newVal) {
      this.getProducts();
    }
  },
  methods: {
    getPayload() {
      let data = this.filter?this.filter:{};
      return {
        ...data,
        location: this.currentLocation
      };
    },
    getProducts() {
      this.$cs.product
        .create({
          data: this.getPayload(),
          params: {
            limit: 12,
            order: this.order
          }
        })
        .then((res) => {
          this.products = res.data;
          this.productsList = res.data.results;
          this.loading = false;
        });
    },
    loadMore(page=null) {
      this.$cs.product.create({
        resource: page,
        data: this.oneFilter,
        params: this.oneFilter,
        pageSize: this.pageSize
      })
      .then(res => {
        const ls = res.data;
        this.productsList = [...this.productsList, ...ls.results];
        this.products = res.data;
      })
    }
  },
};
</script>
<style lang="stylus" scoped>
.bg-dk {
  background-color: #dddddd;
}
.ads-list {
  li {
    width: 24%;
    min-width: 24%;
  }
}
@media (max-width: 850px) {
  .ads-list li {
    width: 32.3%;
    min-width: 32.3%;
  }
}
@media (max-width: 500px) {
  .ads-list li {
  }
}
</style>