<template>
  <div class="inp-search-ele" :style="$width < $devices.tab?{display: 'none'}:{}">
    <div class="input-group">
      <input
        type="text"
        v-model="search_text"
        @focus="focus = true"
        class="form-control"
        placeholder="Search Car, Bike, Mobile and other.."
        autocomplete="false"
      />
      <div class="input-group-btn">
        <button class="btn" @click="startSearch()">
          <span class="fa fa-search"></span>
        </button>
      </div>
    </div>
    <div class="search-words-block" v-if="focus && searchWords">
      <ul class="list-inline words-list">
        <template v-for="(item, idx) in searchWords">
          <li :key="idx" @click="selectWord(item)">
            {{ item.label }}
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      search_text: null,
      focus: false,
      searchWords: null,
    };
  },
  watch: {
    search_text: function () {
      if (this.search_text && this.focus) {
        this.getSearchWords();
      } else {
        this.searchWords = null;
      }
    },
  },
  created() {},
  methods: {
    selectWord(item) {
      this.focus = false;
      this.search_text = item.label;
      this.searchWords = null;
      let root = `/search/${item.category?item.category+'/':''}?text=${item.search}`;
      this.$router.push(root);
    },
    getSearchWords() {
      this.$cs.anyRequest
        .create({
          data: {
            search: this.search_text,
            action: "SEARCH_WORD",
          },
        })
        .then((res) => {
          this.searchWords = res.data;
        });
    },
    startSearch() {
      this.focus = false;
      this.searchWords = null;
      if (this.search_text === null) {
        this.$router.push("/search");
      } else {
        let root = "/search/" + this.search_text;
        this.$router.push(root);
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
.inp-search-ele {
  display: block;
  color: #333333;
  border: 2px solid #000000;
  padding: 0px;
  font-size: 0.9em;
  letter-spacing: 0.3px;
  min-width: 270px;
  border-radius: 4px;
  min-width: 30vw;
}

input {
  border: none;
  padding: 8px;
  width: 100%;
}

.search-input {
  margin-left: 15px;
  color: #333333;
  border: 2px solid #000000;
  padding: 0px;
  font-size: 0.9em;
  letter-spacing: 0.3px;
  min-width: 270px;
  border-radius: 4px;
  min-width: 20vw;

  input {
    border: none;

    &::focus {
      outline: none;
    }
  }

  .btn {
    outline: none;
  }
}

.search-words-block {
  position: absolute;
  top: 100%;
  left: 15px;
  right: 0;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0 0 4px 4px;
  z-index: 20;

  .words-list {
    margin: 0;

    li {
      padding: 5px;
      font-size: 0.95rem;
      border-bottom: 1px solid #efefef;
      cursor: pointer;
    }
  }
}
</style>