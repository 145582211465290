<template>
    <div class="eb-header">
        <div class="container-fluid">
            <div class="row justify-content-center eb-header-top">
                <div class="col-12 max-content-view pad-0">
                    <div class="row margin-0">
                        <div class="col pad-10 max-fit-content">
                            <router-link class="eb-brand" to="/">
                                <img src="/logo.png" class="header-logo" alt="EBechana" />
                            </router-link>
                        </div>
                        <div class="col-2 pad-0">
                            <input-location-element />
                        </div>
                        <div class="col pad-0">
                            <input-search-element />
                        </div>
                        <div class="col pad-0 text-right" v-if="$width > $devices.tab">
                            <ul class="eb-top-menu">
                                <li>
                                    <router-link to="/mobile-app">
                                        <fal-icon name="mobile-alt" height="1rem" /> &nbsp;Get App
                                    </router-link>
                                </li>
                                <li>
                                    <router-link to="/offers" style="color: orange;">
                                        <fal-icon name="tags" height="1rem" /> &nbsp;Offers &amp; Deals
                                    </router-link>
                                </li>
                                <li class="no-border">
                                    <label v-if="profile && profile.name" class="hover-menu" for="Account">
                                        My Account&nbsp;<fal-icon name="chevron-down" variant="far" height="0.5rem" />
                                        <div class="hover-menu-content">
                                            <ul class="hover-menu-list">
                                                <li><router-link to="/my-ads">My Ads</router-link></li>
                                                <!-- <li><router-link to="/my-poster">My Banners</router-link></li> -->
                                                <li><router-link to="/account">Profile</router-link></li>
                                                <!-- <li><router-link to="/billing">Billing</router-link></li> -->
                                                <li><router-link to="/billing">Logout</router-link></li>
                                            </ul>
                                        </div>
                                    </label>
                                    <!-- <button
                                        v-else
                                        class="btn btn-sm btn-plain-link bold"
                                        @click="$store.dispatch('requestLogin')"
                                    >
                                        <fal-icon name="lock" variant="fas" height="1rem" /> Login/Signup
                                    </button> -->
                                    <button
                                        v-else
                                        class="btn btn-sm btn-primary"
                                        @click="$store.dispatch('requestLogin')"
                                    >
                                        Sign in
                                    </button>
                                </li>
                            </ul>
                        </div>
                         <div v-else class="col pad-0 text-right">
                            <ul class="eb-top-menu">
                                <li>
                                    <router-link to="/mobile-app">
                                        <fal-icon name="mobile-alt" height="1rem" />
                                    </router-link>
                                </li>
                                <!-- <li>
                                    <router-link to="/dowload-app">
                                        <fal-icon name="camera" height="1rem" /> &nbsp;Create Post
                                    </router-link>
                                </li> -->
                                <li class="no-border">
                                    <router-link v-if="profile && profile.name" to="/profile">
                                        <fal-icon name="user" variant="far" height="1.2rem" />
                                    </router-link>
                                    <span v-else @click="$store.dispatch('requestLogin')">
                                        <fal-icon name="lock" variant="fas" height="1rem" />
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="$width >= $devices.tab" class="row justify-content-center">
                <div class="col-12 max-content-view pad-0">
                    <ul class="sub-menu-list">
                        <li><router-link to="/search/Mobiles+Tablets/">Mobiles</router-link></li>
                        <li><router-link to="/search/Cars+Bikes/">Bikes</router-link></li>
                        <li><router-link to="/search/Cars+Bikes/">Cars</router-link></li>
                        <li><router-link to="/search/Electronics+Appliances/">Electronics</router-link></li>
                        <li><router-link to="/search/Properties/">Properties</router-link></li>
                        <li><router-link to="/search/Jobs/">Jobs</router-link></li>
                        <li><router-link to="/search/Fashion+Jwellery/">Fashion</router-link></li>
                        <li><router-link to="/search/">All Categories</router-link></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import InputLocationElement from "@/components/InputLocationElement.vue";
import InputSearchElement from "@/components/InputSearchElement.vue";
export default {
    components: {
        InputLocationElement,
        InputSearchElement
    },
    data() {
        return {
            profilea: JSON.parse(localStorage.getItem('user_details')) || null,
        }
    }
}
</script>
<style lang="stylus" scoped>
.eb-header
    position: fixed
    top: 0
    width: 100vw
    min-height: 12vh
    z-index: 100
    background-color #fff
    border-bottom: 1px solid #ddd
.eb-header-top
    padding: 8px 0
    border-bottom: 1px solid #eee
.eb-brand
    cursor pointer
    img
        max-height: 3vh
.eb-top-menu
    list-style: none
    display: flow-root
    float: right
    margin: 0
    padding: 9px 0
    li
        float left
        padding: 1px 15px
        border-right: 1.5px solid #ddd
        &.no-border
            border: none
        a, label
            display: flex
            align-items: center
            color: #000
            font-size: 14px
            font-weight: 500
            cursor pointer
            &:hover
                color: #0052CC
        button
            font-size: 12px
            margin: 0
.hover-menu
    position relative
    &:hover  .hover-menu-content
        display: block
    .hover-menu-content
        position absolute
        top 100%
        right: 0
        background-color: #fff
        padding: 0px 10px
        width: min-content
        border-radius: 4px
        border: 1px solid #ddd
        display: none
        &:hover
            display: block
        .hover-menu-list
            list-style none
            padding: 0
            display: flow-root
            width: 120px
            li
                width: 100%
                border: none
                text-align: left
                padding: 10px 0px
.sub-menu-list
    list-style: none
    margin: 0
    padding: 0
    display: flow-root
    li
        float: left
        padding: 4px 10px
        a
            color: #000
            font-size: 12px
            font-weight: 500
            letter-spacing: 0.1px
            &:hover
                color: #0052CC

@media (max-width: 400px) {
    .eb-header {
        height: auto;
        border: none
    }
    .eb-brand {
        cursor: pointer;
        img {
            max-height: 2vh;
        }
    }
}
</style>