import { mapState } from "vuex";
export default {
    data() {
        return {
            serviceModule: null
        }
    },
    computed: {
        ...mapState({
            profile: (state) => state.account.profile,
            cats: (state) => state.cats,
            subCats: (state) => state.subCats,
            catMappings: (state) => state.catMappings,
        }),
    },
    methods: {
    }
}
