const account = {
  state: () => ({
    profile: JSON.parse(localStorage.getItem("user_details")) || null,
    myAds: [],
    myBanners: [],
    chats: [],
    chatHistory: {},
    selectedChat: null,
    conversations: [],
    notifications: [],
    unreadCount: 0,
    notification: null,
    favorites: [],
    connected: false,
  }),
  mutations: {
    addmyAds(state, data) {
      state.myAds = data;
      // console.log("added myAds...");
    },
    addmyBanners(state, data) {
      state.myBanners = data;
      // console.log("added myBanners...");
    },
    addmyChats(state, data) {
      state.chats = data;
      // console.log("added Chats...");
    },
    addChatDetail(state, data) {
      state.chatHistory[data.chatId] = data;
      if(state.selectedChat && state.selectedChat.id === data.chatId) {
        state.conversations = state.chatHistory[data.chatId].messages;
      }
    },
    addChat(state, data) {
      state.chats.unshift(data);
    },
    addNotifications(state, data) {
      state.notification = data;
      state.notifications = [...state.notifications, ...data.results];
      state.unreadCount = 0;
      state.notifications.forEach(item => {
        if(item.status && item.status==="unread") state.unreadCount++;
      });
      console.log("added Notifications...");
    },
    updateProfile(state, data) {
      state.profile = data;
      // console.log("Updated Profile...");
    },
    selectedChat(state, data) {
      state.selectedChat = data;
      if(data && state.chatHistory[data.id] && state.chatHistory[data.id].messages) {
        state.conversations = state.chatHistory[data.id].messages;
      } else {
        state.conversations = [];
      }
    },
    socket_new(state, data) {
      console.log("new message received", data);
      // state.chatHistory[data._id].messages.unshift(data.data);
      // const audio = new Audio("/tone.mp3");
      // const playPromise = audio.play();
    },
    socket_info(state, data) {
      console.log("new info received", data);
    },
    socket_chat(state, data) {
      if(typeof data === "object") {
        switch(data.action || "simple") {
          case "new":
            state.chatHistory[data._id] = data.data;
            if(state.selectedChat.id === data._id) {
              state.conversations = state.chatHistory[data._id].messages;
            }
            break;
          case "append":
            if(state.chatHistory[data._id]) {
              state.chatHistory[data._id].messages.unshift(data.data);
            }
            // if(state.selectedChatId === data._id) {
            //   state.messagesList.push(data.data);
            // }
            break;
          default:
            console.log("Need to be implemented...");
        }
      }
      // const audio = new Audio("/tone.mp3");
      // const playPromise = audio.play();
    },
  },
  actions: {
    callAccount(context) {
      context.dispatch("checkProfile");
      // context.dispatch("getProfile");
      context.dispatch("getmyAds");
      // context.dispatch("getmyChats");
      // context.dispatch("getNotifications");
    },
    selectedChat(context, chat) {
      context.commit("selectedChat", chat);
    },
    getmyAds(context) {
      this._vm.$cs.myproduct.list({pageSize: 100}).then(
        (res) => {
          context.commit("addmyAds", res.data.results);
        },
        (err) => {
          console.log(err.response);
        }
      );
    },
    // getmyAds(context) {
    //   this._vm.$cs.banner.list({}).then(
    //     (res) => {
    //       context.commit("addmyBanners", res.data.results);
    //     },
    //     (err) => {
    //       console.log(err.response);
    //     }
    //   );
    // },
    getmyChats(context) {
      this._vm.$cs.chat.list({}).then(
        (res) => {
          context.commit("addmyChats", res.data.results);
          res.data.results.forEach(chat => {
            context.dispatch("getChat", chat);
          })
        },
        (err) => {
          console.log(err.response);
        }
      );
    },
    addChat(context, chat) {
      context.commit("addChat", chat);
    },
    getChat(context, chat) {
      this._vm.$cs.message.list({
        resource: `${this._vm.$cs.message.api}${chat.id}`
      }).then(
        (res) => {
          context.commit("addChatDetail", res.data);
        },
        (err) => {
          console.log(err.response);
        }
      );
    },
    getNotifications(context) {
      // this._vm.$cs.notification
      //   .list({
      //     params: {
      //       sort: "-created_on",
      //     },
      //   })
      //   .then(
      //     (res) => {
      //       context.commit("addNotifications", res.data);
      //     },
      //     (err) => {
      //       console.log(err.response);
      //     }
      //   );
    },
    getProfile(context) {
      const that = this;
      this._vm.$cs.profile.list({})
      .then(res => {
        localStorage.setItem("user_details", JSON.stringify(res.data));
        context.commit('updateProfile', res.data);
      }, err => {
          console.log(err.response.data);
      })
    },
    checkProfile(context) {
      let profile = JSON.parse(localStorage.getItem("user_details")) || null;
      context.commit("updateProfile", profile);
      if (profile && !this.state.account.connected) {
        // console.log("need to be connected");
        // this._vm.$socket.emit("register", { userId: profile.id });
        // setTimeout(function () {
        //   try {
        //     this._vm.$socket.emit("register", { email: "ebechana_"+profile.id });
        //     console.log("reconnected");
        //   } catch {
        //     console.log("unable to re-connect");
        //   }
        // }, 5000);
      }
    },
  },
  getters: {},
};

export default account;
