<script>
import axios from "axios";
import geohash from 'ngeohash'
/* eslint class-methods-use-this: "error" */
class REST {
  pageSize = 200;

  // eslint-disable-next-line
  get token() {
    return localStorage.getItem("Token");
  }

  // eslint-disable-next-line
  get token_title() {
    return "Bearer";
  }

  list({ resource = null, params = {}, pageSize = null }) {
    const parameters = params;
    let resourceUrl = resource;
    if (resourceUrl === null) {
      resourceUrl = this.api;
    }
    parameters.limit = pageSize === null ? this.pageSize : pageSize;
    return axios.get(resourceUrl, {
      params: parameters
    });
  }

  retrive({ resource = null, params = {}, page = 1, pageSize = null }) {
    const parameters = params;
    let resourceUrl = resource;
    if (resourceUrl === null) {
      resourceUrl = this.api;
    }
    parameters.page = page;
    parameters.limit = pageSize === null ? this.pageSize : pageSize;
    return axios.get(resourceUrl, {
      params: parameters
    });
  }

  create({ resource = null, params = {}, data = {} }) {
    const parameters = params;
    let resourceUrl = resource;
    if (resourceUrl === null) {
      resourceUrl = this.api;
    }
    return axios({
      method: "post",
      url: resourceUrl,
      data,
      params: parameters
    });
  }

  update({ resource = null, params = {}, data = {} }) {
    const parameters = params;
    let resourceUrl = resource;
    if (resourceUrl === null) {
      resourceUrl = this.api;
    }
    return axios({
      method: "put",
      url: resourceUrl,
      data,
      params: parameters
    });
  }

  destroy({ resource = null, params = {} }) {
    const parameters = params;
    let resourceUrl = resource;
    if (resourceUrl === null) {
      resourceUrl = this.api;
    }
    return axios({
      method: "delete",
      url: resourceUrl,
      params: parameters
    });
  }

  // To generate the signed link for upload to S3
  getSignedLinkForUpload({
    fileName,
    fileType,
    access = true,
    params = {},
    resource = null,
    type = "assets"
  }) {
    const parameters = params;
    let resourceUrl = resource;
    if (resourceUrl === null) {
      resourceUrl = `${process.env.VUE_APP_API_URL}/api/get-signed-url/`;
    }
    return axios({
      method: "post",
      url: resourceUrl,
      data: {
        file_name: fileName,
        file_type: fileType,
        public: access,
        type
      },
      params: parameters
    });
  }
}

// Authentication
class Authentication {
  setToken = token => {
    localStorage.setItem("Token", token);
  };

  getToken = () => localStorage.getItem("Token") || "";

  getCurrentUser = () => localStorage.getItem("profile") || {};

  // To remove the token from localstorage
  removeLocalStorage = () => {
    localStorage.clear();
  };

  // To check user is authenticated
  // To validate the token
  isAuthenticated = (to, from, next) => {
    if (localStorage.getItem("Token")) {
      next();
    } else {
      window.location.href = `/?login=true&redirect=${to.path}`;
    }
  };

  signup(data = {}) {
    return axios({
      method: "post",
      url: `${process.env.VUE_APP_API_URL}/signup/`,
      data
    });
  }

  // To login using email & password
  login(data) {
    localStorage.setItem("API_DOMAIN", process.env.VUE_APP_API_URL);
    return axios({
      method: "post",
      url: `${process.env.VUE_APP_API_URL}/login/`,
      data
    });
  }

  otp(data) {
    localStorage.setItem("API_DOMAIN", process.env.VUE_APP_API_URL);
    return axios({
      method: "post",
      url: `${process.env.VUE_APP_API_URL}/otp/`,
      data
    });
  }

  // To remove the token from server & localstorage
  logout() {
    this.removeLocalStorage();
    window.location.href = '/';
  }

  // send reset password link
  forgotPassword(data) {
    return axios({
      method: "post",
      url: `${process.env.VUE_APP_API_URL}/forgot-password/`,
      data
    });
  }

  // Check reset password link
  checkResetPasswordLink(id) {
    return axios({
      method: "post",
      url: `${process.env.VUE_APP_API_URL}/check-password/${id}/`
    });
  }

  // Check & Reset Password
  checkAndResetPassword(id, password) {
    return axios({
      method: "post",
      url: `${process.env.VUE_APP_API_URL}/reset-password/${id}/`,
      data: {
        password
      }
    });
  }
}

class GeoLocation {
  latitude = null;
  longitude = null;
  hash = null;
  address = null;
  instance = null;
  geocoder = new google.maps.Geocoder();
  init()
  {
    if( navigator.geolocation )
    {
      // Call getCurrentPosition with success and failure callbacks
      navigator.geolocation.getCurrentPosition( (position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        console.log(this.latitude, this.longitude);
        this.getCompleteAddress({
          latlng: `${this.latitude},${this.longitude}`,
          key: `${process.env.VUE_APP_GOOGLE_MAPS_KEY}`,
          position: new google.maps.LatLng(this.latitude,this.longitude)
        });
      }, () => {
        // Could not obtain location
        console.log("could not locate")
      });
    }
    else
    {
      alert("Sorry, your browser does not support geolocation services.");
    }
  }

  detectLocation() {
    return new Promise(async(resolve, reject) => {
      if( navigator.geolocation ){
        navigator.geolocation.getCurrentPosition( async (position) => {
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;
          console.log(this.latitude, this.longitude);
          this.geocoder.geocode({ location: new google.maps.LatLng(this.latitude,this.longitude)}, async (results, status) => {
            this.address = await this.formatAddress(results);
            resolve(this.address);
          });
        }, () => {
          // Could not obtain location
          console.log("could not locate");
          resolve({});
        });
      }
      else
      {
        console.log("Sorry, your browser does not support geolocation services.");
        resolve({});
      }
    });
  }

  formatAddress(instance) {
    let address = {};
    if(instance && instance.length > 0) {
      const singleAddress = instance[0];
      address.address = singleAddress.formatted_address;
      singleAddress.address_components.forEach(item => {
        item.types.forEach(itm => {
          if(itm !== 'political') {
            address[itm] = item.long_name || item.short_name;
          }
        });
      });
    }
    console.log("slelcted address:", address);
    const finalAddress = {
      address: address.address || null,
      landmark: address.neighborhood || null,
      road: address.route || null,
      landmark: address.landmark || null,
      area: address.sublocality || null,
      city: address.locality || null,
      country: address.country || null,
      pincode: address.postal_code || null,
      state: address.administrative_area_level_1 || null,
      district: address.administrative_area_level_2 || null,
      subarea: address.sublocality_level_2 || null,
    };
    return finalAddress;
  }

  getCompleteAddress(params) {
    const that = this;
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ location: params.position}, (results, status) => {
      that.address = that.formatAddress(results);
    });
  }
  // packaging logic
  getPointsAddress(points) {
    return new Promise((resolve, reject) => {
      this.geocoder.geocode({ location: points}, async (results, status) => {
        const address = await this.formatAddress(results);
        resolve(address);
      });
    });
  }
  // eslint-disable-next-line
  get latlng() {
    return new google.maps.LatLng(this.latitude,this.longitude);
  }
  // var geohash = require('ngeohash');
  // console.log(geohash.encode(37.8324, 112.5584));
  // // prints ww8p1r4t8
  // var latlon = geohash.decode('ww8p1r4t8');
  // console.log(latlon.latitude);
  // console.log(latlon.longitude);
  get geohash() {
    // this.hash = geohash.encode(this.latitude, this.longitude);
    // return this.hash;
  }
  get getCompleteHash() {
    const resp = {
      position: {
        latitude: this.latitude,
        longitude: this.longitude
      },
      // hash: geohash.encode(this.latitude, this.longitude)
    }
    return resp;
  }
  success(position)
  {
    this.latitude = position.coords.latitude;
    this.longitude = position.coords.longitude;
    console.log(this.latitude);
    console.log(this.longitude);
  }
  fail()
  {
      // Could not obtain location
      console.log("could not locate")
  }
  // find distance b/w 2 points
  distance(lat1, lon1, lat2, lon2, unit) 
  {
    if ((lat1 == lat2) && (lon1 == lon2)) {
      return 0;
    }
    else {
      var radlat1 = Math.PI * lat1/180;
      var radlat2 = Math.PI * lat2/180;
      var theta = lon1-lon2;
      var radtheta = Math.PI * theta/180;
      var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = dist * 180/Math.PI;
      dist = dist * 60 * 1.1515;
      if (unit=="K") { dist = dist * 1.609344 }
      if (unit=="N") { dist = dist * 0.8684 }
      return dist;
    }
  }
}

// Noty
class Noty {
  // eslint-disable-next-line
  success(message, title = "Success") {
    notification.success({
      message: title,
      description: message
    });
  }
  // eslint-disable-next-line
  warning(message, title = "Warning") {
    notification.warning({
      message: title,
      description: message
    });
  }
  // eslint-disable-next-line
  error(message, title = "Attention Required") {
    notification.error({
      message: title,
      description: message
    });
  }
  // eslint-disable-next-line
  info(message, title = "Info") {
    notification.info({
      message: title,
      description: message
    });
  }
}

// Profile
class Profile extends REST {
  api = `${process.env.VUE_APP_API_URL}/api/profile/`;
  changePassword(data) {
    return axios({
      method: "put",
      url: `${process.env.VUE_APP_API_URL}/change-password/`,
      data
    });
  }

  verifyOTP(data) {
      return axios({
        method: 'post',
        url: `${process.env.VUE_APP_API_URL}/verify-otp/`,
        data,
      });
    }

    resendOTP() {
      return axios.get(`${process.env.VUE_APP_API_URL}/verify-otp/`);
    }

    // list() {
    //   return axios.get(this.api);
    // }

    // update({ params = {}, data = {} }) {
    //   const parameters = params;
    //   return axios({
    //     method: 'put',
    //     url: process.env.VUE_APP_API_URL,
    //     data,
    //     params: parameters,
    //   });
    // }
}

class Product extends REST {
  api = `${process.env.VUE_APP_API_URL}/api/product/`;
}

class Banner extends REST {
  api = `${process.env.VUE_APP_API_URL}/api/banner/`;
}

class BannerPool extends REST {
  api = `${process.env.VUE_APP_API_URL}/api/banner-pool/`;
}

class User extends REST {
  api = `${process.env.VUE_APP_API_URL}/api/user/`;
}

class Products extends REST {
  api = `${process.env.VUE_APP_API_URL}/api/products/`;
}

class Store extends REST {
  api = `${process.env.VUE_APP_API_URL}/api/store/`;
}

class SearchProduct extends REST {
  api = `${process.env.VUE_APP_API_URL}/api/search-product/`;
}

class Search extends REST {
  api = `${process.env.VUE_APP_API_URL}/api/search/`;
}

class Favorite extends REST {
  api = `${process.env.VUE_APP_API_URL}/api/favorite/`;
}

class Contact extends REST {
  api = `${process.env.VUE_APP_API_URL}/contact/`
}

class Chat extends REST {
  api = `${process.env.VUE_APP_API_URL}/api/chat/`
}

class Message extends REST {
  api = `${process.env.VUE_APP_SOCKET_URL}/chat/`
}

class Lookup extends REST {
  api = `${process.env.VUE_APP_API_URL}/api/lookup/`
}

class AnyRequest extends REST {
  api = `${process.env.VUE_APP_API_URL}/api/any-request/`;
}

export default {
  REST,
  profile: new Profile(),
  auth: new Authentication(),
  product: new SearchProduct(),
  banner: new Banner(),
  message: new Message(),
  search: new Search(),
  products: new Products(),
  myproduct: new Product(),
  store: new Store(),
  contact: new Contact(),
  chat: new Chat(),
  user: new User(),
  lookup: new Lookup(),
  geo: new GeoLocation(),
  favorite: new Favorite(),
  bannerpool: new BannerPool(),
  anyRequest: new AnyRequest(),
};
</script>