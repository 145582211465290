<template>
  <div class="eb-skelton">
    <EBHeader />
    <!-- <headera /> -->
    <!-- location modal -->
    <!-- <div class="cities-list-mb" v-if="mvisible_list">
      <div class="cities-list-mb-body">
        <div class="close-tag">
          <span>Select Location</span
          ><ion-icon
            @click="mvisible_list = false"
            name="close-outline"
          ></ion-icon>
        </div>
        <div class="search-detect" @click="updateLocation(geo_location)">
          <div class="iconn">
            <fal-icon
              name="location"
              class="fa-1x fa"
              size="1x"
              style="font-size: 1rem"
            />
          </div>
          <div class="location-inf">
            <span>Use Current Location</span>
            <p v-if="geo_location">
              {{ geo_location.city }}, {{ geo_location.state }}
            </p>
            <p v-else>Loading...</p>
          </div>
          <span></span>
        </div>
        <div class="cities-search-block">
          <input
            type="search"
            name=""
            v-model="location_label"
            placeholder="Enter city, area"
            id=""
          />
        </div>
        <div
          class="popular-cities"
          v-if="location_label === null || location_label === ''"
        >
          <h5>POPULAR CITIES</h5>
          <ul class="popular-list with-img" v-if="popular">
            <template v-for="(item, index) in popular">
              <li
                :key="index"
                @click="updateLocation(item)"
                :class="{
                  active: selected_location && selected_location.id === item.id,
                }"
              >
                <img :src="'/cities/' + item.icon" alt="" srcset="" />
                <span>{{ item.name }}</span>
              </li>
            </template>
          </ul>
          <div v-if="popular.length < 1" class="pad-10 text-center">
            <span>Loading...</span>
          </div>
          <div class="pad-5 text-center">
            <span>or</span><br /><br />
            <button
              @click="updateLocation()"
              class="btn btn-outline-secondary btn-sm"
            >
              Search All India
            </button>
          </div>
        </div>
        <div class="other-cities" v-if="false">
          <h5>OTHER CITIES</h5>
          <ul class="popular-list" v-if="cities">
            <template v-for="(item, idx) in cities">
              <template v-if="inputSearch && inputSearch !== ''">
                <li
                  :key="idx"
                  v-if="
                    item.name.toLowerCase().indexOf(inputSearch) > -1 ||
                    item.state.toLowerCase().indexOf(inputSearch) > -1
                  "
                  @click="updateLocation(item)"
                  :class="{ active: payload && payload.id === item.id }"
                >
                  <ion-icon
                    name="location"
                    style="position: relative; top: 2px"
                  ></ion-icon>
                  {{ item.name }}, {{ item.state }}
                </li>
              </template>
              <template v-if="!inputSearch || inputSearch === ''">
                <li
                  :key="idx"
                  :class="{ active: payload && payload.id === item.id }"
                >
                  <ion-icon
                    name="location"
                    style="position: relative; top: 2px"
                  ></ion-icon>
                  {{ item.name }}, {{ item.state }}
                </li>
              </template>
            </template>
          </ul>
          <div v-if="cities.length < 1" class="pad-10 text-center">
            <span>Loading...</span>
          </div>
        </div>
        <div>
          <ul>
            <template v-for="(item, idx) in cities">
              <li
                @click="updateLocation(item)"
                v-if="
                  location_label &&
                  item.name
                    .toLowerCase()
                    .indexOf(location_label.toLowerCase()) !== -1
                "
                :key="idx"
              >
                <span class="fa fa-map-marker"></span> {{ item.name }},
                {{ item.state }}
              </li>
            </template>
          </ul>
        </div>
      </div>
    </div> -->
    <!-- <div class="post-ad-mbl" v-if="$width < $devices.tab">
      <ul class="list-inline mbl-menu">
        <li :class="$route.name === 'Search' ? 'active' : ''">
          <router-link to="/search">
            <fal-icon
              name="search"
              class="fa-1x fa"
              size="1x"
              variant="fas"
              style="font-size: 1.1rem"
            />
            <span class="mbl-link-text">Search</span>
          </router-link>
        </li>
        <li :class="$route.name === 'Chat' ? 'active' : ''">
          <router-link to="/inbox">
            <fal-icon
              name="comment-alt-dots"
              class="fa-1x fa"
              variant="far"
              size="1x"
              style="font-size: 1.1rem"
            />
            <span class="mbl-link-text">Inbox</span>
          </router-link>
        </li>
        <li :class="$route.name === 'PostFreeAd' ? 'active' : ''">
          <router-link to="/post-free-ad">
            <fal-icon
              name="plus-circle"
              class="fa-1x fa"
              variant="fal"
              size="1x"
              style="font-size: 1.6rem"
            />
            <span class="mbl-link-text">Sell</span>
          </router-link>
        </li>
        <li :class="$route.name === 'MyAds' ? 'active' : ''">
          <router-link to="/my-ads">
            <fal-icon
              name="th-list"
              class="fa-1x fa"
              variant="fal"
              size="1x"
              style="font-size: 1.1rem"
            />
            <span class="mbl-link-text">My Ads</span>
          </router-link>
        </li>
        <li :class="$route.name === 'MyAccount' ? 'active' : ''">
          <router-link to="/my-account">
            <fal-icon
              name="user"
              class="fa-1x fa"
              variant="far"
              size="1x"
              style="font-size: 1.1rem"
            />
            <span class="mbl-link-text">Account</span>
          </router-link>
        </li>
      </ul>
    </div> -->
    <div class="eb-header-space">{{" "}}</div>
    <div class="eb-content-block">
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div class="col-12 max-content-view pad-0">
            <div class="row margin-0">
              <div class="col-12 pad-0">
                <router-view />
              </div>
            </div>
          </div>
        </div>
        <footera />
      </div>
    </div>
    <sn-modal
      :show="requestLogin && profile === null"
      :width="popupWidth"
      top="5%"
      @close="$store.dispatch('requestLogin', false)"
    >
      <template v-slot:body>
        <login @close="$store.dispatch('requestLogin', false)"></login>
      </template>
    </sn-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Login from "../views/Login.vue";
import Footera from "./components/footer.vue";
import EBHeader from "./components/EBHeader.vue";
import InputLocationElement from "@/components/InputLocationElement.vue";
import InputSearchElement from "@/components/InputSearchElement.vue";
export default {
  components: {
    EBHeader,
    Login,
    Footera,
    InputLocationElement,
    InputSearchElement,
  },
  data() {
    return {
      showModal: false,
      products: null,
      sub_nav: false,
      cities: null,
      location_label: null,
      visible_list: false,
      mvisible_list: false,
      popular: null,
      geo_location: null,
      search_text: null,
      selected_location: null,
    };
  },
  computed: {
    ...mapState({
      profile: (state) => state.account.profile,
      location: (state) => state.location,
      requestLogin: (state) => state.requestLogin,
      popupWidth: function() {
        if(window.width < 700) {
          return '80%';
        } else if(window.width < 1023) {
          return '300px';
        }
        return '400px';
      }
    }),
  },
  created() {
    if (this.$route.query && this.$route.query.login) {
      this.$store.dispatch("requestLogin");
    }
    this.cities = this.$constants.cities;
    this.popular = this.$constants.popular;
  },
  watch: {
    mvisible_list: function (newVal, oldVal) {
      if (newVal) {
        this.$cs.geo.detectLocation().then((location) => {
          location.name = location.city;
          console.log("Geo data:", location);
          this.geo_location = location;
        });
      }
    },
  },
  methods: {
    startSearch() {
      if (this.search_text === null) {
        this.$router.push("/search");
      } else {
        let root = "/search/" + this.search_text;
        this.$router.push(root);
      }
    },
    closeCororna() {
      localStorage.setItem("close-corona", "Yes");
    },
    logout() {
      localStorage.clear();
      window.location.href = "/";
    },
    updateLocation(item = null) {
      console.log(item);
      this.location_label = null;
      this.visible_list = false;
      this.mvisible_list = false;
      this.$store.dispatch("updateCustomLocation", item);
    },
  },
};
</script>
<style lang="stylus" scoped>
.eb-skelton {
  background-color: #fff;
}
// .skelton {
//   min-height: 100vh;
//   background-color: white;

//   .search-input {
//     margin-left: 15px;
//     color: #333333;
//     border: 2px solid #000000;
//     padding: 0px;
//     font-size: 0.9em;
//     letter-spacing: 0.3px;
//     min-width: 270px;
//     border-radius: 4px;
//     min-width: 20vw;

//     input {
//       border: none;

//       &::focus {
//         outline: none;
//       }
//     }

//     .btn {
//       outline: none;
//     }
//   }

//   .location-block {
//     margin-left: 0px;

//     .location-grid {
//       position: relative;
//       cursor: pointer;
//       color: #333333;
//       border: 2px solid #000000;
//       padding: 6.5px 8px;
//       font-size: 0.9em;
//       letter-spacing: 0.3px;
//       min-width: 270px;
//       border-radius: 4px;

//       .fa-map-marker {
//         color: #333333;
//       }

//       .fa-chevron-down {
//         float: right;
//         padding-top: 4px;
//       }

//       .location-title {
//         min-width: 85%;
//         border: none;
//         outline: none;
//       }

//       .cities-list {
//         position: absolute;
//         top: 105%;
//         left: 0;
//         right: 0;
//         background-color: #ffffff;
//         box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.2);

//         ul {
//           list-style: none;
//           margin: 0px;
//           padding: 0px;
//           max-height: 40vh;
//           overflow-y: scroll;

//           li {
//             padding: 10px;

//             &:hover {
//               background-color: #eeeeee;
//             }

//             &.nohover:hover {
//               background-color: #ffffff;
//             }
//           }
//         }
//       }
//     }
//   }

//   .header-logo {
//     max-height: 42px;
//   }

//   .navbar {
//     // min-height 60px
//     // height 8vh
//     background-color: white;
//     margin-bottom: 0px;
//     padding: 5px;
//     border: none;

//     // border-bottom 1px solid #ddd
//     .navbar-brand {
//       font-weight: bold;
//       color: #000;
//     }

//     .navbar-nav {
//       li {
//         a {
//           color: #000;
//         }
//       }
//     }
//   }
// }

// .search-detect {
//   margin: 10px 20px;
//   display: flex;
//   padding: 0px;
//   border: 1px solid #ddd;
//   color: #0595d8;
//   border-radius: 4px;
//   cursor: pointer;

//   .iconn {
//     width: 50px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     font-size: 1.8em;
//   }

//   .location-inf {
//     padding: 5px 10px;

//     span {
//       font-weight: bold;
//       font-size: 1em;
//     }

//     p {
//       font-size: 0.8em;
//       margin: 0;
//     }
//   }
// }
.eb-header-space {
  height: 13.5vh;
}
.eb-content-block {
  display: block;
  width: 100vw;
  max-width: 100vw;
  overflow: hidden;
  padding: 0;
  margin: 0;
}
@media (max-width: 850px) {
  .eb-header-space {
    height: 12vh;
  }
}
@media (max-width: 400px) {
  .eb-header-space {
    height: 8vh;
  }
  .eb-content-block {
    padding: 4px;
  }
}

.cities-list-mb {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1100;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 10vh;

  .cities-list-mb-body {
    background-color: #ffffff;
    border-radius: 3px;
    max-width: 70%;
    display: table;
    margin: 0 auto;

    .close-tag {
      display: block;
      background-color: #ddd;
      padding: 10px;

      span {
        font-weight: bold;
      }

      ion-icon {
        float: right;
        color: red;
        font-size: 1.5em;
        font-weight: bold;
        cursor: pointer;
      }
    }

    .cities-search-block {
      padding: 14px;

      input {
        width: 100%;
        padding: 7px 10px;
        border: 1px solid #ddd;
        outline: none;
        border-radius: 3px;
      }
    }

    .popular-cities {
      padding: 14px;

      h5 {
        text-align: center;
        font-weight: bold;
      }

      .popular-list {
        list-style: none;
        margin: 0px;
        padding: 0px;
        display: flow-root;

        li {
          width: 16.6%;
          float: left;
          text-align: center;
          cursor: pointer;

          img {
            max-width: 60%;
          }

          span {
            display: block;
            font-weight: 500;
            letter-spacing: 0.2px;
          }
        }
      }
    }

    .c-l-mb {
      padding: 10px;
      // height 9vh
      // border-bottom 1px solid #dddddd
      display: flex;
      justify-content: space-between;

      ion-icon {
        width: 10%;
        font-size: 1.7em;
        padding-top: 5px;
        font-weight: bold;
      }

      input {
        border: none;
        outline: none;
        max-width: 50%;

        &:disabled {
          background: #ffffff;
        }
      }
    }

    ul {
      list-style: none;
      margin: 0px;
      padding: 0px;
      max-height: 90vh;
      overflow-y: scroll;

      li {
        padding: 10px;

        &:hover {
          background-color: #eeeeee;
        }

        &.active {
          background-color: #eeeeee;
        }

        &.nohover:hover {
          background-color: #ffffff;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .post-ad-mbl {
    background-color: #fff;
    border-radius: 0px 0px 10px #ccc;
    padding: 0px;
    position: fixed;
    bottom: -1px;
    right: 0;
    left: 0;
    z-index: 100;
    display: none;
    height: 5rem;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.1);
    border-top: 1px solid #eee;

    .btn {
      font-weight: 500;
      letter-spacing: 0.4px;
      font-size: 1em;
      box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.2);
    }
  }
}

.sub-nav {
  position: fixed;
  background-color: #ffffff;
  top: 8vh;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 110;

  .sub-nav-menu {
    list-style: none;
    margin: 0px;
    padding: 0px;

    li {
      padding: 10px;
      letter-spacing: 0.3px;
      font-size: 1.2em;

      ion-icon {
        position: relative;
        top: 3px;
        margin-right: 10px;
      }
    }
  }
}

.all-cats {
  padding: 10px;

  ion-icon {
    font-size: 1.5em;
    position: relative;
    top: 2px;
  }
}

.cat-bg {
  // overflow-y scroll
  .top-cat-list {
    list-style: none;
    // display flow-root
    margin: 0px;
    padding: 0px;
    max-width: 100%;

    li {
      cursor: pointer;
      padding: 9px 10px;
      font-size: 0.95em;
      letter-spacing: 0.4px;
      float: left;
      font-weight: 500;
      margin: 0px 14px;
      position: relative;

      &:hover .cat-detail-list {
        display: block;
      }

      & .cat-detail-list {
        position: absolute;
        background-color: #fff;
        min-width: 200px;
        box-shadow: 0px 0px 3px #aaa;
        z-index: 1032;
        top: 98%;
        left: 0px;
        border-radius: 3px;
        padding: 12px 10px;
        display: none;

        &:hover {
          display: block;
        }

        & .st-ca {
          list-style: none;
          padding: 0px;
          margin: 0px;
          width: 100%;

          li {
            width: 100%;
            background-color: transparent;
            padding: 8px 0px;

            a {
              color: inherit;
              text-decoration: none;
            }

            &:hover {
              color: #0594da;
            }
          }
        }
      }

      &:hover {
        background-color: #fbfbfb;
      }

      ion-icon {
        font-size: 1.1em;
        position: relative;
        top: 3px;
      }
    }
  }
}

@media (max-width: 767px) {
  .cities-list-mb {
    padding: 0px;

    .cities-list-mb-body {
      width: 100%;
      max-width: 100%;
      min-height: 100vh;

      .popular-cities {
        // padding 20px 0px
        .popular-list {
          li {
            width: 33.3%;
          }
        }
      }
    }
  }

  .post-ad-mbl {
    display: block;
  }

  .header {
    background-color: white;

    .location-block {
      margin: 0;
      padding: 5px 0px 0px;
      width: 60% !important;

      .location-grid1 {
        cursor: pointer;
        color: #333333;
        // border 2px solid #dddddd
        padding: 3px 3px 3px 7px;
        letter-spacing: 0.3px;
        float: right;
        font-size: 0.8em;
        width: 90% !important;
        text-align: right;

        ion-icon {
          position: relative;
          top: 3px;
          height: 0.8em;
          padding: 0px;
        }

        .fa {
          margin-right: 3px;
          border: none;
        }

        span {
          border-bottom: 1px dashed #333;
          font-weight: 600;
        }

        .location-title {
          border: none;
          outline: none;
          width: 85%;
        }
      }
    }

    .navbar {
      height: 8vh;
      border-bottom: 1px solid red;
      overflow: hidden;
      padding: 5px;
      border-bottom: 1px solid #dddddd;

      // justify-content flex-start
      ion-icon {
        font-size: 1.7em;
        padding: 10px 7px;
      }
    }

    .header-logo {
      max-height: 5vh;
    }
  }

  .u-info {
    padding: 25px 15px;
  }

  .footer {
    .foot-logo {
      width: 20vw;
    }

    .foot-tag {
      padding: 7vw 0px 0px;
      font-size: 1.1em;
    }
  }
}

.short-banner {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 20px;
  text-align: center;

  .banner-body {
    background-color: #fff;
    padding: 10px;
    display: inline-block;
    border-radius: 3px;

    img {
      max-width: 100%;
      max-height: 80vh;
    }
  }
}

.mbl-menu {
  display: flex;
  justify-content: space-evenly;
  margin: 0 -20px;

  li {
    padding-top: 1vh;

    a {
      text-align: center;
      display: block;
      font-size: 1.4rem;
      color: #777;
      text-decoration: none;
    }

    .mbl-link-text {
      font-size: 0.9rem;
      display: block;
    }

    &.active {
      a {
        color: #044865;
        text-decoration: none;
      }

      .mbl-link-text {
        color: #044865;
      }
    }
  }
}
</style>
