<template>
  <div>
    <div class="inp-loc-ele">
      <p>
        <fal-icon
          name="map-marker-alt"
          class="fa"
          size="1x"
          style="font-size: 0.5rem; top: -1px; position: relative"
        />
        {{ locationLabel }}
      </p>
    </div>
    <template v-if="false">
      <div class="inp-loc-ele">
        <div class="inp-loc" v-show="focus">
          <!-- <sn-input
            type="location"
            :rules="['required']"
            label="Enter Job Location"
            v-model="inputValue"
            :value="inputValue"
            :showLabel="false"
          /> -->
          <input
            ref="autocomplete"
            autocomplete="false"
            type="text"
            name="name"
            v-model="inputValue"
            placeholder="Enter city, area etc."
            :id="myID"
            @focus="setFocus()"
          />
        </div>
        <div class="inp-loc-lbl" @click="openSearch()" v-show="!focus">
          <fal-icon
            name="map-marker-alt"
            class="fa"
            size="1x"
            style="font-size: 0.5rem; top: -3px; position: relative"
          />
          <span> {{ locationLabel }}</span>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  data() {
    return {
      currentLocation: localStorage.getItem("location")
        ? JSON.parse(localStorage.getItem("location"))
        : null,
      myID: Math.random().toString(36).substring(7),
      autocomplete: false,
      autocompleteText: null,
      country: "IN",
      focus: false,
      inputValue: "hyde",
      geolocation: {
        geocoder: null,
        loc: null,
        position: null,
      },
    };
  },
  created() {
    if (this.currentLocation && this.currentLocation.name) {
      this.autocompleteText = this.currentLocation.name;
    }
  },
  computed: {
    locationLabel: function () {
      if (this.autocompleteText === null) {
        return "All India";
      } else {
        return this.autocompleteText;
      }
    },
  },
  mounted: function () {
    const options = {};
    if (this.types) {
      options.types = [this.types];
    }
    if (this.country) {
      options.componentRestrictions = {
        country: this.country,
      };
    }
    //eslint-disable-next-line
    this.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById(this.myID),
      options
    );
    this.autocomplete.addListener("place_changed", this.onPlaceChanged);
  },
  methods: {
    openSearch() {
      this.inputValue = "";
      this.focus = !this.focus;
      this.$refs.autocomplete.focus();
    },
    setBlur() {},
    setFocus() {},
    extendValidation(valid) {
      return valid;
    },
    getFormatName(address) {
      if (address.address) {
        return `${
          address.address.locality ? address.address.locality + ", " : ""
        }${
          address.address.administrative_area_level_1
            ? address.address.administrative_area_level_1 + ", "
            : ""
        }${address.address.country ? address.address.country : ""}`;
      }
      return address.name;
    },
    updateLocation(address) {
      let addressObj = {
        name: address.name,
        state: null,
        city: null,
        area: null,
      };
      if (address.administrative_area_level_1) {
        addressObj.state = address.administrative_area_level_1;
      }
      if (address.locality) {
        addressObj.city = address.locality;
      }
      if (address.sublocality_level_2) {
        addressObj.area = address.sublocality_level_2;
      }
      if (addressObj.area === null && address.sublocality_level_1) {
        addressObj.area = address.sublocality_level_1;
      }
      if (addressObj.area === null && address.sublocality) {
        addressObj.area = address.sublocality;
      }
      if (addressObj.area === null && address.locality) {
        addressObj.area = address.locality;
      }
      if (addressObj.area === null && addressObj.city) {
        addressObj.area = addressObj.city;
      }
      return addressObj;
    },
    processAddress(address) {
      let locationObj = {
        name: address.formatted_address,
        address: {},
        lat: address.geometry.location.lat(),
        lng: address.geometry.location.lng(),
      };
      address.address_components.forEach((item) => {
        item.types.forEach((tag) => {
          if (tag !== "political") {
            locationObj.address[tag] = item.long_name;
          }
        });
      });
      locationObj.name = this.getFormatName(locationObj);
      return this.updateLocation(locationObj);
    },
    onPlaceChanged() {
      console.log("onPlaceChanged");
      let place = this.autocomplete.getPlace();
      const resultData = this.processAddress(place);
      this.$emit("selected-value", resultData);
      localStorage.setItem("location", JSON.stringify(resultData));
      this.autocompleteText = resultData.name;
      this.inputValue = resultData.name;
      this.focus = false;
    },
    onKeyPress() {
      // this.$emit("keypress", event);
    },
    onKeyUp() {
      // this.$emit("keyup", event);
    },
    clear() {
      this.autocompleteText = "";
    },
    makeFocus() {
      this.$refs.autocomplete.focus();
    },
    blur() {
      this.$refs.autocomplete.blur();
    },
    update(value) {
      this.autocompleteText = value;
    },
  },
};
</script>
<style lang="stylus" scoped>
.inp-loc-ele {
  cursor: pointer;
  color: #333333;
  border: 2px solid #000000;
  font-size: 0.9em;
  letter-spacing: 0.3px;
  border-radius: 4px;
  position: relative;
  max-width: 200px;
  p {
    padding: 7px 10px;
    margin: 0;
    font-size: 1rem;
  }
}

@media (max-width: 767px) {
  .inp-loc-ele {
    border: none;
    p {
      text-align: right;
      padding-right: 20px;
      font-size: 1.2rem;
    }
  }
}

input {
  border: none;
  padding: 8px;
  width: 100%;
}

.pac-container {
  background-color: #FFF;
  z-index: 20;
  position: fixed;
  display: inline-block;
  float: left;
}

.modal {
  z-index: 20;
}

.modal-backdrop {
  z-index: 10;
}

.inp-loc {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10000;
}

.inp-loc-lbl {
  padding: 8px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 20;
}

.inp-loc-ele-mbl {
  display: block;
  padding-top: 2vh;

  span {
    font-size: 1.1rem;
  }
}
</style>